import React from 'react';
import { makeStyles } from '@mui/styles';
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  toastContainer: {
    left: "50%",
    transform: 'translateX(-50%)',
    position: "fixed",
    bottom: 40,
  },
  container: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#fff",
    padding: "16px 32px",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.15)",
    borderRadius: 48,
  },
  title: {
    fontSize: 16,
    fontWeight: 700,
    color: "#30354F",
    marginRight: 20,
  },
  links: {
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    color: "#5D6598",
    fontWeight: 400,
  },
  linkIcon: {
    height: 16,
    width: 16,
    marginRight: 8,
  },
  gotoIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#CE2C3B",
    backgroundColor: "#FCEEF0",
    height: 40,
    width: 40,
    marginLeft: 32,
    borderRadius: 8,
    cursor: "pointer",
  },
});

const ReportLink = ({ reportId, title, links, toast }) => {
  const classes = useStyles();

  const render = () => {
    return (
      <div className={classes.container}>
        <span className={classes.title}>{title}</span>
        <Link to={`/reports/${reportId}`} className={classes.gotoIcon}>
          <ArrowForwardIcon />
        </Link>
      </div>
    )
  };

  if(toast) {
    return <div className={classes.toastContainer}>{render()}</div>
  }

  return render();
};

export default ReportLink;
