import Header from "./Header";

const AppLayout = ({currentUser, onLogout, children}) => (
      <div id="app-container">
        <Header {...{currentUser, onLogout}}/>
        <main>
          {children}
        </main>
        {/* Footer */}
      </div>
  )

export default AppLayout;