import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  overlay: {
    position: "fixed",
    display: "block",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    zIndex: 200,
  },
  sidebar: {
    height: "100vh",
    width: 700,
    maxWidth: "90%",
    position: "fixed",
    zIndex: 201,
    top: 0,
    right: 0,
    backgroundColor: "#fff",
    boxShadow: "-2px 0px 8px rgba(0, 0, 0, 0.05)",
    overflowX: "hidden",
    transition: "right 330ms ease-in-out",
    animationDuration: "300ms",
  },
});

const Sidebar = ({ close, children, ...props }) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.overlay} onClick={() => close && close()}></div>
      <div className={classes.sidebar + " animated slideInRight"} {...props}>
        <div>
          {children}
        </div>
      </div>
    </React.Fragment>
  )
};

export default Sidebar;