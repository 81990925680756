import { pubmedResultsAdapter } from "adapters";
import { useQuery } from "react-query";
import { client, formatToDate } from "utils";
import AppConfig from 'config';

export function usePubmedScrapper(values={}, page, shouldBeFetched) {
    const {applicant="", device="", from, to}=values 
    const params = {
      applicant,
      device,
      from: formatToDate(from, 'YYYY/MM/DD'),
      to: formatToDate(to, 'YYYY/MM/DD'),
      page: page.number,
      size: page.size
    };
    const queryString = new URLSearchParams(params).toString();
    const url = `${AppConfig.backendURL}/api/pubmed?${queryString}`;
    const label = "pubmed";

    return useQuery({
        queryKey: ['searchPubmedScrapper', applicant, device, from, to, page.number, page.size],
        queryFn: ({signal}) => {
            let data = []
            if(!applicant && !device){
                data.total = {
                    [label]: 0
                }
                return data
            } 
            return client(url,{signal})
            .then((res)=>{
                return pubmedResultsAdapter(res.data, label);
            })
            .catch((err) => {
                console.error(err.name + ": " + err.message);
                data = [];
                data.total = {
                    [label]: -1
                };
                return data
            })
        },
        enabled:shouldBeFetched(label),
    });
}