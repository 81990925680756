
import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { useParams } from "react-router-dom";
import { reportService } from 'services';
import { toast } from "react-toastify";
import SearchResultsItem from "components/SearchResultsItem";
import OpendataSwissResultsItem from "components/OpendataSwissResultsItem";
import AppConfig from 'config';
import Editor from "./Editor";
import PreviewModal from "components/PreviewModal";
import earthIcon from './icons/earth.svg';

const sources = AppConfig.sources;
const getSourceFromLabel = (label) => sources.find(f => f.label === label);

const useStyles = makeStyles({
    sourceSection: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    sourceTitle: {
        fontWeight: '700',
        fontSize: '20px',
        lineHeight: '26px',
        textAlign: 'center',
        color: '#000000',
        marginBlock: '24px 16px',
        display: 'flex',
        alignItems: 'center',
        '&::before, &::after': {
            content: '" "',
            flex: 1,
            borderBottom: '1px solid #000',
        },
        '&:not(:empty)::before':{
            marginRight: '2em',
        },
        '&:not(:empty)::after':{
            marginLeft: '2em',
        },
    },
    result: {
        padding: '8px 24px',
    },
    iframeContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        "& img": {
            maxWidth: "100%",
        }
    },
    commentArea: {
        backgroundColor: '#EEEEEE',
        minHeight: 56,
        // borderRadius: 16,
        color: '#777777',
        '&::placeholder': {
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '18px'
        }

    },
    metaData: {
        '& strong': {
            textTransform: 'capitalize',
        }
    },
    actionBtn: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 32,
        height: 32,
        cursor: 'pointer',
        background: '#F1F1F1',
        borderRadius: "6px",
        border: 'none',
      },
})

export default function ReportSourceSection({ searchId, source, results: res }) {
    const { id: reportId } = useParams();
    const classes = useStyles();
    const [results, setResults] = useState(res);
    const [showModal, setShowModal] = useState(false);
    const [previewInfos, setPreviewInfos] = useState({link:"", source:""});

    const deleteResult = (resultId) => {
        setResults(res => res.filter(r => r.id !== resultId))
    }
    if (results.length > 0) {
        return <div className={classes.sourceSection} key={source}>
            <span className={classes.sourceTitle}>
                {getSourceFromLabel(source)?.name}
            </span>
            {results.map((result, index) => {
                return <Result key={index} {...{ classes, searchId, result, index, deleteResult, reportId, setShowModal, setPreviewInfos }} />
            })}
      <PreviewModal previewInfos={previewInfos} showModal={showModal} setShowModal={setShowModal}></PreviewModal>

        </div>
    }

    return null
}


const Result = ({ classes, searchId, result, index, deleteResult, reportId, setShowModal, setPreviewInfos }) => {
    const handleResultDeletion = () => {
        reportService.deleteResult(result['id'])
            .then(() => {
                deleteResult(result['id'])
            }).catch(() => {
                toast.error("Couldn't remove the result.");
            })
    }

    const saveComment = (resultId) => (range, source, editor) => {
        reportService.saveResultComment(resultId, editor.getHTML()).then((data) => {
            if (data.data.success) {
                // toast.success("Comment Saved!", { autoClose: 1000 });
                console.log("Comment Saved!");
            }
        }).catch(() => {
            // toast.error("Couldn't save the comment.");
            console.log("Couldn't save the comment.");
        })
    }

    if(result.source === 'swissmedic_fscas') {
      result.mime = 'text/pdf';
    }

    return <div className={classes.result}>
        {/* <ResultMetaData metaData={result['metaData']} /> */}
        {result.source === 'opendata_swiss' ? (
          <OpendataSwissResultsItem key={result.link} currentSearchId={searchId} source={result.title} {...result} {...JSON.parse(result.description)} type="reportResult" onDelete={handleResultDeletion} />
        ) : (
          <SearchResultsItem key={result.link} currentSearchId={searchId} source={result.title} date={result.result_date} {...result} reportId={reportId} actions={["showModal", "delete"]} onDelete={handleResultDeletion} >
            <button
                className={classes.actionBtn}
                onClick={(e) => {
                setPreviewInfos({...result, currentSearchId:searchId, date: result.result_date});
                setShowModal(true)
                }}
                >
                <img src={earthIcon} alt="website modal" />
            </button>
          </SearchResultsItem>
        )}
        <ResultContent content={result['non_editable_html']} />
        <Editor {...{ content: result['editable_html'], saveContent: saveComment(result['id']), editorId: `comment-toolbar-${result['id']}-${index}` }} >
            <div className={`${classes.commentArea} comment-box`} />
        </Editor>
    </div>;
}


export const ResultContent = ({ content }) => {
    const classes = useStyles()
    return <div
        className={classes.iframeContent}
        dangerouslySetInnerHTML={{ __html: content }}
    />
}
