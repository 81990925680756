import React from 'react';

export const useUpdateQueryStringValueWithoutNavigation = (
  queryParams,
) => {
  React.useEffect(() => {
    const currentSearchParams = new URLSearchParams(window.location.search)
    const newSearchParams = new URLSearchParams(queryParams)
    if (currentSearchParams.toString() === newSearchParams.toString()) return

    const newUrl = [window.location.pathname, newSearchParams.toString()]
      .filter(Boolean)
      .join('?')

    window.history.replaceState(null, '', newUrl)
  }, [queryParams])
}