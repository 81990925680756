import { makeStyles } from '@mui/styles';
import closeIcon from './icons/close.svg';

const useStyles = makeStyles({
    container:{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        fontSize:14,
        padding: '7px 14px',
        height: '32px',
        backgroundColor: '#F7F7F8',
        borderRadius: '6px',
        width: 'fit-content',
        color: '#6C6D82',
        '& strong':{
            marginInline:'6px 12px',
            color: '#30354F',
        },
        '& img':{
            cursor:'pointer',
        }
    }
});

const FilterBadge=({label, name, setSelectedFilters})=>{
    const classes = useStyles();
    const cancelFilter = ()=>{
        setSelectedFilters(sf=>sf.filter(f=>f!==label))
    }
    return <div className={classes.container}>
        <span>Database</span>
        <strong>{name}</strong>
        <img src={closeIcon} alt='close' onClick={cancelFilter}/>
    </div>
}

export default FilterBadge;