import { useState } from "react";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { makeStyles } from "@mui/styles";
import UpArrowIcon from './icons/upArrow.svg';
import DownArrowIcon from './icons/downArrow.svg';

const useStyles = makeStyles({
  container: {
    position: 'relative',
  },
  selector: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 'bold',
    fontSize: '14px',
    color: '#30354F',
    width: '192px',
    border: '1px solid #A6ABC9',
    boxSizing: 'border-box',
    borderRadius: '6px',
    backgroundColor: '#FFFFFF',
    padding: '11px 16px',
    cursor: 'pointer',

  },
  dropdown: {
    position: 'absolute',
    top: 'calc(100% + 8px)',
    width: '100%',
    fontSize: '14px',
    padding: '16px 24px 16px 16px',
    color: '#30354F',
    background: '#FFFFFF',
    border: '1px solid #ECEDF3',
    boxSizing: 'border-box',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '6px',
    zIndex: 1,
  },
  dropdownOption: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    marginBottom: '8px',
    cursor: 'pointer',
    '& input, label': {
      margin: 0,
      padding: 0,
      cursor: 'pointer',
    },
  },
})

const sizes = [10, 15, 20, 25]

const SizeSelector = ({ size: pageSize, setSize }) => {
  const classes = useStyles()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const onSizeSelectClick = () => setIsDropdownOpen(o => !o)

  const handleSizeChange = (e) => {
    const { target: { value } } = e;
    setSize(value);
    setIsDropdownOpen(false);
  }

  return (
    <ClickAwayListener onClickAway={() => setIsDropdownOpen(false)}>
      <div className={classes.container}>
        <span className={classes.selector} onClick={onSizeSelectClick}>
          <span>Showing {pageSize} results</span>
          <img
            src={isDropdownOpen ? UpArrowIcon : DownArrowIcon}
            alt="selector arrow"
          />
        </span>
        <div
          className={classes.dropdown}
          style={{ display: !isDropdownOpen && "none" }}
        >
          {sizes.map((size) => (
            <div key={size} className={classes.dropdownOption}>
              <input
                id={size}
                type="radio"
                name="size"
                value={size}
                onClick={handleSizeChange}
                defaultChecked={size === pageSize}
              />
              <label htmlFor={size}>Showing {size} results</label>
            </div>
          ))}
        </div>
      </div>
    </ClickAwayListener>
  );
}

export default SizeSelector;