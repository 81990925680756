import usePagination from '@mui/material/usePagination';
import { makeStyles } from '@mui/styles';
import arrowIcon from './icons/arrow.svg';

const useStyles = makeStyles({
  container: {
    display: "flex",
    justifyContent: 'space-between',
    alignItems: 'center',
    listStyle: "none",
    padding: 0,
    margin: 0,
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '17px',
    color: '#414766',
    height:80,
    borderBottom:'1px solid #E3E3E3',
    borderTop:'1px solid #E3E3E3',
  },
  pageNumbers:{
    display:'flex',
    gap:'4px',
  },
  pageNumber:{
    width: '40px',
    height: '40px',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor:'pointer',
  }
});

export default function Pagination({count, pageNumber, handlePageNumberClick, handlePreviousPage, handleNextPage}) {
    const classes = useStyles()
  const { items } = usePagination({
    count,
  });

  return (
    <nav className={classes.container}>
        {items.map(({ page, type, selected, onClick, ...item }, index) => {
          let children = null;
          if (type === 'start-ellipsis' || type === 'end-ellipsis') {
            children = '…';
          } else if (type === 'page') {
            children = <div className={classes.pageNumber} style={{backgroundColor:pageNumber===page?'#FCEEF0':undefined}} onClick={()=>{handlePageNumberClick(page); onClick();}} {...item} >{page}</div>
          } else {          
            if(type==='previous'){
              children = (<span style={{marginRight:'auto', cursor:'pointer'}} onClick={()=>{handlePreviousPage();  onClick();}}  {...item}> <img src={arrowIcon} alt='arrow'  style={{marginRight: '8px', transform: 'rotate(180deg)'}}/> Previous</span>)
            }else{
              children = <span style={{marginLeft:'auto', cursor:'pointer'}} onClick={()=>{handleNextPage();  onClick();}}  {...item}>Next <img src={arrowIcon} alt='arrow' style={{marginLeft: '8px'}}/></span>
            }
          }
          return <li key={index}>{children}</li>;
        })}
    </nav>
  );
}