import { useCallback, useMemo } from "react";
import { useQueries, useQuery } from "react-query";
import { zefixResultsAdapter } from "adapters";
import AppConfig from 'config';
import { client } from "utils";

export function useZefix(q, page={number: 1, size:10}, shouldBeFetched){
    const [{ data: legalForm }, { data: registerOffice }] = useZefixLegalFormAndOffice()
    const getZefixResults = useCallback(({signal}) => {
        const url = AppConfig.backendURL + '/api/search_company/?q=' + q;
    
        if(!q){
            return;
        } 
        return client(url,{signal})
        .then(res=>res.data)
        .catch((err) => {
            const data=[];
            if(err.response.status===404){
                data.totalPages=0;
                data.totalResults=0;
                return data;
            }
            console.error(err.name + ": " + err.message);
            data.totalPages=0;
            data.totalResults=-1;
            return data
        })
    }, [q]);

    const result = useQuery({
        queryKey: ['searchZefix', q],
        queryFn: getZefixResults,
        enabled: shouldBeFetched('zefix'),
    });

    const data = useMemo(()=>({...result, data:zefixResultsAdapter(result.data, legalForm, registerOffice, page)}),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [legalForm, page, registerOffice, result.data])

    return data;
}

export function useZefixSuggestions(q){
    const params = {
        prefix:q,
        deletedFirms: false,
        formerNames: false
    };
    const queryString = new URLSearchParams(params).toString();
    const url ='https://www.zefix.ch/ZefixREST/api/v1/autocomplete.json?'+queryString;
    return useQuery({
        queryKey: ['zefixSuggestions', q],
        queryFn: ({signal}) => {
            if(!q) return [];
            return fetch(url, {signal})
            .then(res=>res.json())
            .then((res)=>{
                return res?.suggestions;
            })
            .catch((err) => {
                console.error(err.name + ": " + err.message);
                return []
            })
        },
        enabled:false,
    });
}

export function useZefixLegalFormAndOffice(){
    const getLegalForm=()=> fetch(
        "https://www.zefix.ch/ZefixREST/api/v1/legalForm.json"
      ).then((res) => res.json());
      
      const getRegisterOffice = ()=> fetch(
        "https://www.zefix.ch/ZefixREST/api/v1/registerOffice.json"
      ).then((res) => res.json())

    return useQueries([
        {
          queryKey: "legalForm",
          queryFn:getLegalForm,
          cacheTime: Infinity,
        },
        {
          queryKey: "registerOffice",
          queryFn: getRegisterOffice,
          cacheTime: Infinity,
        }
      ]);
}