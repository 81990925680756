import { makeStyles } from '@mui/styles';
import errorIcon from './icons/error.svg';

const useStyles = makeStyles({
    databaseError:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        flexDirection:'column',
        backgroundColor: '#FFFFFF',
        border: '1px solid #ECEDF3',
        boxSizing: 'border-box',
        borderRadius: '16px',
        height: '248px',
        '& i':{
          display:'flex',
          justifyContent:'center',
          alignItems:'center',
          width:'64px',
          height:'64px',
          borderRadius:'50%',
          backgroundColor:'#FBEEEF',
        },
        '& h4':{
          fontWeight: 'bold',
          fontSize: '18px',
          lineHeight: '23px',
          textAlign: 'center',
          color: '#30354F',
          marginBlock:'16px 8px',
        },
        '& p':{
          fontSize: '16px',
          lineHeight: '20px',
          textAlign: 'center',
          color: '#A6ABC9',
          width: 'max(40%, 300px)',
        }
      }
});

const Error=({title, description})=>{
    const classes = useStyles();
  return( <div className={classes.databaseError}>
    <i>
      <img src={errorIcon} alt='error'/>
    </i>
    <h4>{title}</h4>
    <p>{description}</p>
  </div>)
}

export default Error;