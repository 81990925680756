import { client } from "utils";

export const getActiveReports = (page, size, searchQuery, sortDirection) => {
    return client.get("/api/active_reports/", { params: { page, size, searchQuery, sortDirection } });
};

export const getSavedReports = (page, size, searchQuery, sortDirection) => {
    return client.get("/api/saved_reports/", { params: { page, size, searchQuery, sortDirection } });
};

export const createReport = (report) => {
    return client.post("/api/reports/", report);
};

export const updateReport = (report) => {
    return client.put("/api/reports/", report);
};

export const toggleReportActivity = (reportId, active) => {
    return client.put("/api/reports/activity/", { reportId, active });
};

export const getReport = (reportId) => {
    return client.get(`/api/reports/${reportId}/`);
};

export const getLastActiveReport = () => {
  return client.get(`/api/reports/last_active/`);
}

export const saveZefixToActiveReport = (report_id, zefix) => {
  return client.post(`/api/reports/save_zefix/`, { report_id, zefix});
}

export const saveOpendataSwissToActiveReport = (report_id, opendata_swiss) => {
  return client.post(`/api/reports/save_opendata_swiss/`, { report_id, opendata_swiss});
}

export const saveLinkToActiveReport = (report_id, link) => {
  return client.post(`/api/reports/save_link/`, { report_id, link});
}

export const saveIframeContent = (body) => {
  return client.post(`/api/reports/save_iframe_content/`, body);
}

export const saveResultComment = (result_id, comment) => {
  return client.post(`/api/reports/save_result_comment/`, { result_id, comment});
}

export const deleteResult = (result_id) => {
  return client.delete(`/api/reports/delete_result/${result_id}/`);
}

export const saveSearch = (body) => {
  return client.post(`/api/reports/save_search/`, body);
}

export const saveGeneralInformations = (body) => {
  return client.post(`/api/reports/save_general_informations/`, body);
}

export const deleteReport = (report_id) => {
  return client.delete(`/api/reports/delete/${report_id}/`);
}
