import React from "react";
import { CircularProgress } from "@mui/material";
import "./loading.css";
import { makeStyles } from "@mui/styles";

export const LoadingScreen = () => {
  return (
    <div className="lds-ellipsis-center">
      <div className="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "150px 40px",
  },
  loader: {
    color: "#5D6598",
  },
});

export const Loader = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <CircularProgress className={classes.loader}/>
    </div>
  );
};
