import { fdaResultsAdapter } from "adapters";
import { useQuery } from "react-query";
import { client, formatToFDADate } from "utils";
import AppConfig from "config";
const sources = AppConfig.sources;
const FDASourcesLabels = sources.filter(s=>s.source==='fda_scrapper').map(s=>s.label);

export function useFDAScrapper(values = {}, page, shouldBeFetched) {
  const { applicant = "", device = "", from, to } = values;
  const params = {
    applicant,
    device,
    from: formatToFDADate(from),
    to: formatToFDADate(to),
    page: page.number,
    size: page.size,
  };
  const queryString = new URLSearchParams(params).toString();
  const url = `${AppConfig.backendURL}/api/fda_search/?${queryString}`;

  return useQuery({
    queryKey: [
      "searchFDAScrapper",
      applicant,
      device,
      from,
      to,
      page.number,
      page.size,
    ],
    queryFn: ({signal}) => {
      if (!applicant && !device) return { totalPages: 0 };
      return client(url,{signal})
        .then((res) => {
          const data = fdaResultsAdapter(res.data, page);
          return data;
        })
        .catch((err) => {
          console.error(err.name + ": " + err.message);
          const response = {totalPages: 0,}
          FDASourcesLabels.forEach((label)=>{
            if(!response.total) response.total = {}
            response[label]=[]
            response.total[label] = -1
          })
          return response
        });
    },
    enabled: shouldBeFetched(FDASourcesLabels),
  });
}
