import React from 'react';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

function getPagesCount(total, size) {
    return !isNaN(total) && !isNaN(size) ? Math.ceil(total / size) : 1;
}

function getPages(total, currentPage, size) {
    let pagesCount = getPagesCount(total, size);
    let pageIndex = currentPage + 1;

    let pagesStart = pageIndex % 5 === 0 ? pageIndex - 4 : pageIndex - (pageIndex % 5) + 1;
    let pagesEnd = pagesStart + 4 <= pagesCount ? pagesStart + 4 : pagesCount;
    let pages = [];
    
    for(let i = pagesStart; i <= pagesEnd; i++) {
        pages.push({number: i, selected: i === pageIndex});
    }

    return pages;
}

const useStyles = makeStyles({
    pagination: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
        margin: "30px 0px 0px",
        userSelect: "none",
    },
    paginationItems: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    paginationItem: {
        textAlign: "center",
        fontSize: 14,
        fontWeight: 700,
        outline: 0,
        border: 0,
        backgroundColor: "transparent",
        borderRadius: 8,
        width: 40,
        height: 40,
        cursor: "pointer",
        userSelect: "none",
        color: "#414766",
    },
    paginationItemSelected: {
        backgroundColor: "#FCEEF0",
        color: "#CE2C3B",
    },
    paginationNavigate: {
        display: "flex",
        alignItems: "center",
        fontSize: 14,
        fontWeight: 700,
        color: "#414766",
        userSelect: "none",
        cursor: "pointer",
    },
});

const Pagination = ({
    total,
    page,
    size,
    setPage,
}) => {
    const classes = useStyles();

    const pagesTotalCount = getPagesCount(total, size);
    const pages = getPages(total, page, size);
    if(page >= pagesTotalCount && pagesTotalCount > 0) setPage(pagesTotalCount - 1);

    return (
        <div className={classes.pagination}>
            <span className={classes.paginationNavigate} onClick={() => setPage((page - 1 >= 0) ? page - 1 : 0)}><ArrowRightAltIcon style={{marginRight: 8, transform: "rotate(180deg)"}}/> Previous</span>
            <div className={classes.paginationItems}>
            {pages.map((elem, index) => (
                <button key={index} className={classNames(classes.paginationItem, {[classes.paginationItemSelected]: elem.selected === true})} onClick={() => setPage(elem.number - 1)}>{elem.number}</button>
            ))}
            </div>
            <span className={classes.paginationNavigate} onClick={() => setPage(Math.max((page + 1 < pagesTotalCount) ? page + 1 : pagesTotalCount - 1, 0))}>Next <ArrowRightAltIcon style={{marginLeft: 8}}/></span>
        </div>
    )
};

export default Pagination;