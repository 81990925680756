import { opendataSwissResultsAdapter } from "adapters";
import { useQuery } from "react-query";
import { client } from "utils";
import AppConfig from 'config';

export function useOpendataSwiss(values={}, page, shouldBeFetched) {
    const {applicant=""}=values
    const params = {
      applicant: applicant.replaceAll("\"", ""),
      page: page.number,
      size: page.size
    };
    const queryString = new URLSearchParams(params).toString();
    const url = `${AppConfig.backendURL}/api/opendata_swiss/?${queryString}`;
    const label = 'opendata_swiss'
    return useQuery({
        queryKey: ['searchOpendataSwissScraper', applicant, page.number, page.size],
        queryFn: ({signal}) => {
            if(!applicant) return {totalPages:0}
            return client(url,{signal})
            .then((res)=>{
                const data = opendataSwissResultsAdapter(res.data, page);
                console.log(data)
                return data
            })
            .catch((err) => {
              let data = [];
              console.error(err.name + ": " + err.message);
              data.total = {
                  'opendata_swiss': -1
              };
              return data
            })
        },
        enabled:shouldBeFetched(label),
    });
}
