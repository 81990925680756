import { useQuery } from 'react-query'
import AppConfig from 'config';
import { googleSearchResultsAdapter } from 'adapters'
import { formatToGoogleDate } from 'utils';
import axios from 'axios';
const sources = AppConfig.sources;
const googleSourcesLabels = sources.filter(s=>s.source==='google').map(s=>s.label);
const getQueryParams = (cx, page, from, to, otherParams={}) => {
    const size = page.size > 10 ? 10 : page.size;
    const start = (page.number - 1) * size + 1 >= 91 ? 91 : (page.number - 1) * size + 1;
    const params = {
        key: AppConfig.gcApiKey,
        cx: cx,
        gl: 'ch',
        hl: 'de',
        sort: from && to ? `date:r:${formatToGoogleDate(from)}:${formatToGoogleDate(to)}` : `date`,
        start: start,
        num: size, 
        ...otherParams
    };
    return params;
}

export function useGoogleSearch(values={}, page, shouldBeFetched, all=false) {
    const {applicant="", device="", from, to}=values; 
    const params = getQueryParams(AppConfig.cx, page, from, to);

    const fetchResultsByLabel = (label,{signal})=>{
        if(!applicant && !device) return {totalPages:0}
        const  q = `${applicant}${device ? ' ' + device : ''}${all ? '' : ` more:${label}`}`;
        const queryString = new URLSearchParams({...params, q}).toString();
        const url = "https://www.googleapis.com/customsearch/v1/siterestrict?" + queryString;
        return axios(url,{signal})
        .then((res)=>{
            const data = googleSearchResultsAdapter(res.data.items);
            data.totalResults=+res.data.searchInformation.totalResults || 0;
            data.totalPages=Math.ceil(data.totalResults>=100? 100/page.size:data.totalResults/page.size)
            return data
        })
        .catch((err) => {
            console.error(err.name + ": " + err.message);
            return {totalPages:0, totalResults:-1}
        })
    }
   
    const queryFn = async ({signal})=>{
        const results= await googleSourcesLabels.reduce(async(acc, label)=>{
            const data = await fetchResultsByLabel(label,{signal});
            const nAcc = await acc;
            nAcc['data'][label]=data;
            nAcc['total'][label]=data.totalResults;
            return nAcc;
        }, {data:{},total:{}})
        
        return results;
    } 

    return useQuery({
        queryKey: ['searchGoogle', { applicant, device, from, to, page }],
        queryFn:queryFn,
        enabled:shouldBeFetched(googleSourcesLabels),
    });
}

export function useSwitzerlandGoogleSearch(values={}, page, submited) {
    const {applicant="", device="", from, to}=values; 
    const params = getQueryParams(AppConfig.switzerlandCx, page, from, to, {cr: 'countryCH'});

    const fetchResults = ({signal})=>{
        if(!applicant && !device) return {totalPages:0}
        let data = []
        if(!applicant && !device){
            data.total = {
                switzerland_region_search: 0
            }
            return data
        } 
        const  q = `${applicant}${device ? ' ' + device : ''}`;
        const queryString = new URLSearchParams({...params, q}).toString();
        const url = "https://www.googleapis.com/customsearch/v1?" + queryString;
        return axios(url, {signal})
        .then((res)=>{
            const data = googleSearchResultsAdapter(res.data.items);
            data.total = { switzerland_region_search: +res.data.searchInformation.totalResults || 0}
            // data.totalResults=+res.data.searchInformation.totalResults || 0;
            data.totalPages=Math.ceil(data.totalResults>=100? 100/page.size:data.totalResults/page.size)
            return data
        })
        .catch((err) => {
            console.error(err.name + ": " + err.message);
            data = [];
                data.total = {
                    switzerland_region_search: -1
                };
                return data
        })
    } 

    return useQuery({
        queryKey: ['searchSwitzerlandGoogle', { applicant, device, from, to, page }],
        queryFn: fetchResults,
        enabled: submited,
    });
}

