import { eudamedResultsAdapter } from "adapters";
import { useQuery } from "react-query";
import { client } from "utils";
import AppConfig from 'config';

export function useEudamedEOScrapper(values={}, page, shouldBeFetched) {
    const {applicant=""}=values
    const params = {
      applicant: applicant.trim().replaceAll("\"", ""),
      page: page.number,
      size: page.size
    };
    const queryString = new URLSearchParams(params).toString();
    const url = `${AppConfig.backendURL}/api/eudamed/economic-operators?${queryString}`;
    const label = "eudamed_economic_operators";

    return useQuery({
        queryKey: ['searchEudamedEOScrapper', applicant, page.number, page.size],
        queryFn: ({signal}) => {
            let data = []
            if(!applicant){
                data.total = {
                    [label]: 0
                }
                return data
            }
            return client(url,{signal})
            .then((res)=>{
                return eudamedResultsAdapter(res.data, label);
            })
            .catch((err) => {
                console.error(err.name + ": " + err.message);
                data = [];
                data.total = {
                    [label]: -1
                };
                return data
            })
        },
        enabled:shouldBeFetched(label),
    });
}
export function useEudamedDevicesSppsScrapper(values={}, page, shouldBeFetched) {
    const {applicant="", device=""}=values
    const params = {
      applicant: applicant.trim().replaceAll("\"", ""),
      device: device.trim().replaceAll("\"", ""),
      page: page.number,
      size: page.size
    };
    const queryString = new URLSearchParams(params).toString();
    const url = `${AppConfig.backendURL}/api/eudamed/devices-spps?${queryString}`;
    const label = "eudamed_devices_spps";

    return useQuery({
        queryKey: ['searchEudamedDevicesSppsScrapper', applicant, device, page.number, page.size],
        queryFn: ({signal}) => {
            let data = []
            if(!applicant && !device){
                data.total = {
                    [label]: 0
                }
                return data
            }
            return client(url,{signal})
            .then((res)=>{
                return eudamedResultsAdapter(res.data, label);
            })
            .catch((err) => {
                console.error(err.name + ": " + err.message);
                data = [];
                data.total = {
                    [label]: -1
                };
                return data;
            })
        },
        enabled:shouldBeFetched(label),
    });
}

export function useEudamedCertificatesScrapper(values={}, page, shouldBeFetched) {
    const {applicant="", device=""}=values
    const params = {
      applicant: applicant.trim().replaceAll("\"", ""),
      page: page.number,
      size: page.size
    };
    const queryString = new URLSearchParams(params).toString();
    const url = `${AppConfig.backendURL}/api/eudamed/certificates?${queryString}`;
    const label = "eudamed_certificates";

    return useQuery({
        queryKey: ['searchEudamedCertificatesScrapper', applicant, device, page.number, page.size],
        queryFn: ({signal}) => {
            let data = []
            if(!applicant){
                data.total = {
                    [label]: 0
                }
                return data
            }
            return client(url,{signal})
            .then((res)=>{
                return eudamedResultsAdapter(res.data, label);
            })
            .catch((err) => {
                console.error(err.name + ": " + err.message);
                data.total = {
                    [label]: -1
                };
                return data
            })
        },
        enabled:shouldBeFetched(label),
    });
}
