import { useEffect, useState } from "react";
import AppConfig from 'config';
const sources = AppConfig.sources;
const googleSourcesLabels = sources.filter(s=>s.source==='google').map(s=>s.label);

export const useTotalPages = (page, totals) => {
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    if (page.number === 1) {
      const totalResults = { ...totals };

      //this is because on google you can only request 10 results per page
      //and 10 pages max thus 100 results max
      googleSourcesLabels.forEach((element) => {
        if (
          element in totalResults &&
          totalResults[element] != null &&
          totalResults[element] > 100
        ) {
          totalResults[element] = 100;
        }
      });
      
      const totalMax = Math.max(
        ...Object.values(totalResults)?.map((t) => t || 0)
        );
      setTotalPages(Math.ceil(totalMax / page.size));
    }
  }, [totals, page.size, page.number]);

  return totalPages;
};