import { swissmedicResultsAdapter } from "adapters";
import { useQuery } from "react-query";
import { client, formatToDate } from "utils";
import AppConfig from 'config';

export function useSwissmedicScrapper(values={}, page, shouldBeFetched) {
    const {applicant="", device="", from, to}=values
    const params = {
      applicant,
      device,
      from: formatToDate(from, 'YYYY-MM-DD'),
      to: formatToDate(to, 'YYYY-MM-DD'),
      page: page.number,
      size: page.size
    };
    const queryString = new URLSearchParams(params).toString();
    const url = `${AppConfig.backendURL}/api/swissmedic/?${queryString}`;
    const label = 'swissmedic_fscas'
    return useQuery({
        queryKey: ['searchSwissmedicScrapper', applicant, device, from, to , page.number, page.size],
        queryFn: ({signal}) => {
            if(!applicant && !device) return {totalPages:0}
            return client(url,{signal})
            .then((res)=>{
                const data = swissmedicResultsAdapter(res.data, page);
                return data
            })
            .catch((err) => {
              let data = [];
              console.error(err.name + ": " + err.message);
              data.total = {
                 [label]: -1
              };
              return data
            })
        },
        enabled:shouldBeFetched(label),
    });
}
