import React from "react";
import { makeStyles } from "@mui/styles";
import AutoHeightTextArea from "components/AutoHeightTextArea";
import ReactQuill, { Quill } from 'react-quill';
import ImageResize from 'quill-image-resize-module-react';
import 'react-quill/dist/quill.snow.css';

Quill.register('modules/imageResize', ImageResize);

const useStyles = makeStyles({
  body: {
    display: "flex",
    flexDirection: "column",
    padding: "64px 80px",
    flex: 1,
    "@media print": {
      padding: "25px",
    },
  },
  reportDetails: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  reportDetailsTitle: {
    color: "#30354F",
    fontSize: 24,
    fontWeight: 700,
    marginBottom: 28,
    border: 0,
    outline: 0,
  },
  reportDetailsDescription: {
    color: "#30354F",
    fontSize: 16,
    fontWeight: 400,
    border: 0,
    outline: 0,
    borderLeft: "2px solid #CE2C3B",
    paddingLeft: 16,
    resize: "vertical",
    whiteSpace: "break-spaces",
  },
  reportDetailsSeparator: {
    border: 0,
    borderBottom: "1px solid #E3E3E3",
    margin: "36px 0",
    width: "100%",
  },
  editor: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    flex: 1,
  }
});

const ReportBody = ({
  title,
  setTitle,
  description,
  setDescription,
  content,
  setContent,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.body} id="report-body">
      <div className={classes.reportDetails}>
        <input type="text" className={classes.reportDetailsTitle} value={title} onChange={e => setTitle(e.target.value)} />
        <AutoHeightTextArea className={classes.reportDetailsDescription} value={description} onChange={e => setDescription(e.target.value)} />
        <hr className={classes.reportDetailsSeparator} />
      </div>
      <div className={classes.editor}>
        <ReactQuill
          theme="snow"
          placeholder="Please insert the report's content..."
          value={content || ""}
          onChange={(v) => setContent(v)}
          modules={{
            toolbar: {
              container: "#editor-toolbar",
            },
            clipboard: {
              matchVisual: false,
            },
            imageResize: {
              parchment: Quill.import('parchment'),
              modules: ['Resize', 'DisplaySize']
            }
          }}
          formats={[
            "header",
            "font",
            "size",
            "bold",
            "italic",
            "underline",
            "strike",
            "blockquote",
            "list",
            "bullet",
            "indent",
            "link",
            "image",
            "color",
            "align",
          ]}
        />
      </div>
    </div>
  );
};

export default ReportBody;
