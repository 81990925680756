export const copyToClipboard = (toCopy, toast) => {
  const text = Object.entries(toCopy).reduce((acc, [key, value]) => {
    acc += value ? `${key}: ${value}\n` : "";
    return acc;
  }, "");
  navigator.clipboard.writeText(text).then(
    function () {
      /* clipboard successfully set */
      toast.success("Copied!", { autoClose: 2000 });
    },
    function () {
      /* clipboard write failed */
      toast.error("Couldn't be copied.");
    }
  );
};

export { default } from './CopyToClipboard';