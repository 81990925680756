import React, { useState } from 'react'
import { ReportsList } from 'components/Reports';
import useRequest from 'hooks/useRequest';
import { reportService } from 'services';


const SavedReports = ({ searchQuery, sortDirection }) => {
  const [page, setPage] = useState(0);

  const { loading, error, data, cachedData, setCachedData, setData } = useRequest(() => {
    return reportService.getSavedReports(page, 150, searchQuery, sortDirection);
  }, [page, searchQuery, sortDirection]);

  return (
    <ReportsList
      // list details
      title="Saved reports"
      emptyText="No saved reports"
      // pagination
      page={page}
      size={12}
      setPage={setPage}
      // result
      loading={loading}
      error={error}
      totalReports={(cachedData && cachedData.count) || 0}
      reports={data ? data.reports : []}
      onDelete={(id) => reportService.deleteReport(id).then(()=>{
        setCachedData(data=>({...data, count:data.count-1, reports:data.reports.filter(r=>r.id!==id)}))
        setData(data=>({...data, count:data.count-1, reports:data.reports.filter(r=>r.id!==id)}))
      })}
    />
  );
}

export default SavedReports;
