
import { makeStyles } from '@mui/styles';
import SearchInput from 'components/SearchInput';
import { useRef } from 'react';
import { useQueryClient } from 'react-query';
import ZefixSuggestions from './ZefixSuggestions';


const useStyles = makeStyles({
  container: {
    backgroundColor: "#F3F3F7",
    padding: "64px 64px 40px",
  },
  title: {
    fontSize: '40px',
    lineHeight: '50px',
    display: 'flex',
    alignItems: 'center',
    color: '#000000',
  },
  form: {
    position: 'relative',
    "& button": {
      fontSize: 16,
      color: "#FFF",
      position: 'absolute',
      top: '50%',
      right: 32,
      transform: 'translateY(-50%)',
      width: '96px',
      height: '40px',
      backgroundColor: '#CE2C3B',
      border: 'none',
      borderRadius: '8px',
      cursor: 'pointer',
    }
  },
})

const ResearchSection = ({ formValues, setFormValues, dispatchPagination, setSubmited }) => {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const applicantInputRef = useRef();

  const setValue = (name) => (value) =>
    setFormValues((v) => ({ ...v, [name]: value }));

  const handleSearch = async (e) => {
    e.preventDefault();
    setFormValues((v) => {
      //when searching with new keywords,
      //you want to use the newly generated search id
      //not the current one
      delete v?.id;
      return { ...v };
    });
    queryClient.clear();
    dispatchPagination({ type: 'init' });
    setSubmited(true);
  };

  return (
    <div className={classes.container}>
      <h1 className={classes.title}>Search</h1>
      <form onSubmit={handleSearch} className={classes.form}>
        <SearchInput
          inputRef={applicantInputRef}
          name="applicant"
          value={formValues.applicant}
          setValue={setValue("applicant")}
          placeholder="Search a company or a manufacturer"
          width="41%"
        />
        <ZefixSuggestions
          applicantInputRef={applicantInputRef}
          manufacturer={formValues.applicant}
          setManufacturer={setValue("applicant")}
        />
        <SearchInput
          name="device"
          value={formValues.device}
          setValue={setValue("device")}
          placeholder="Medical device"
          width="59%"
          style={{ paddingRight: "13%" }}
          clearIconStyle={{ right: 'calc(24px + 96px + 32px)' }}
        />
        <button type="submit">Search</button>
      </form>
    </div>
  );
}

export default ResearchSection;