import axios from "axios";
import AppConfig from '../config';
import base64 from "base-64";

const setAccessToken = (token) => {
    localStorage.setItem("scara-ac", base64.encode(token));
};

const setRefreshToken = (token) => {
    localStorage.setItem("scara-rf", base64.encode(token));
}

const clearTokens = () => {
    localStorage.removeItem("scara-ac");
    localStorage.removeItem("scara-rf");
}

export const getAccessToken = () => {
    const accessToken = localStorage.getItem("scara-ac");
    return accessToken ? base64.decode(accessToken) : null;
}

export const getRefreshToken = () => {
    const refreshToken = localStorage.getItem("scara-rf");
    return refreshToken ? base64.decode(refreshToken) : null;
}

export const login = (username, password) => {
    return axios.post(AppConfig.backendURL + "/auth/token/obtain/", { username, password }).then(res => {
        if (res.status === 200) {
            setAccessToken(res.data.access);
            setRefreshToken(res.data.refresh);
        }

        return res.data;
    });
};

export const logout = (onLogout) => {
    return axios.post(AppConfig.backendURL + "/auth/logout/").then(() => {
        clearTokens();
        onLogout && onLogout();
    });
};

export const refreshAccessToken = async () => {
    try {
        const response = await axios.post(AppConfig.backendURL + "/auth/token/refresh/", { "refresh": getRefreshToken() });
        if (response.status === 200) {
            setAccessToken(response.data.access);
            return response.data.access;
        }
    } catch { }
    clearTokens();
}
