import { useContext, useEffect, useState } from 'react';
import { makeStyles } from "@mui/styles";
import Modal from 'react-modal';
import LoaderGif from '../assets/loader.gif';
import CloseIcon from '@mui/icons-material/Close';
import config from 'config';
import AppContext from "AppContext";
import { useParams } from 'react-router-dom';
import { reportService } from 'services';
import AppConfig from 'config';
import { toast } from 'react-toastify';


const modalCustomStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      height: '80%',
      width: '90%',
      padding: 0,
      overflow: '',
    }
  }

  const useStyles = makeStyles({
    iframe: {
      overflow: 'hidden',
      height: '100%',
      width: '100%'
    },
    iframeClose: {
      position: 'absolute',
      top: 24,
      right: 24 + 15,
      cursor: 'pointer',
      fontWeight: 'bold',
      width: 32,
      height: 32,
      background: '#FFFFFF',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '50%',
      color: '#000000',
    },
    iframeHolder: {
      height: '100%',
      width :'100%',
      background: `url(${LoaderGif}) center center no-repeat`,
    },
  });

export default function PreviewModal({ previewInfos:{link, source, title, description="", date=null, currentSearchId, ...rest}, showModal, setShowModal}){
  const classes = useStyles();
  const { id: currentReportId } = useParams();
  const  currentReport = useContext(AppContext).currentReport || {};
  const [previewId, setPreviewId]=useState('')
  
  useEffect(()=>{
    if(link) {
      setPreviewId(`${new URL(link).origin}-${Date.now()}-${Math.floor(Math.random() * 100)}`)}
    },[link])
    
    useEffect(() => {
      const handleIframeMessage = (event) => {
        if (event.origin === AppConfig.backendURL && previewId === event.data.previewId && (currentReport?.id || currentReportId)) {
            reportService.saveIframeContent({
              reportId: parseInt(currentReportId?? currentReport?.id),
              title,
              description,
              date: new Date(date).toISOString(),
              link,
              source,
              searchId: currentSearchId,
              content: event.data.image,
              ...rest
            }).then((data) => {
              if (data.data.success) {
                toast.success("Capture saved into active report!", { autoClose: 2000 });
              }
            }).catch(() => {
              toast.error("Couldn't save the capture.");
            })
      }
    }
    
    window.addEventListener("message", handleIframeMessage)
    return () => {
      window.removeEventListener("message", handleIframeMessage)
    }
  }, [currentReport?.id, currentSearchId, date, description, link, source, title, previewId, currentReportId, rest])

    return <Modal
    isOpen={showModal}
    style={modalCustomStyles}
    contentLabel="Save from source"
  >
    <CloseIcon
      className={classes.iframeClose}
      color="#000"
      onClick={() => setShowModal(false)}
    />
    <div className={classes.iframeHolder}>
      <iframe
        name="resultWebsite"
        className={classes.iframe}
        title="proxy to website"
        src={`${config.backendURL}/proxy/get?report_id=${currentReportId??currentReport?.id}&origin=${config.backendURL}&previewId=${previewId}&url=${encodeURI(link)}`}
        frameborder="0"
        height="100%"
        width="100%"
      />
    </div>
  </Modal>
}