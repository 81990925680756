import { makeStyles } from "@mui/styles";
import React from "react";
// import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    border: '1px solid #ECEDF3',
    alignItems: 'center',
    paddingInline: '24px',
    backgroundColor: '#fff',
    "@media print": {
      display: "none",
    },
  },
});

const CommentToolbar = ({ toolbarId }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div id={toolbarId} style={{ border: 0, }}>
        <select className="ql-header" defaultValue={""} onChange={e => e.persist()} style={{ border: 0 }}>
          <option value="1" />
          <option value="2" />
          <option />
        </select>
        <button className="ql-bold" style={{ marginLeft: 30 }} />
        <button className="ql-italic" />
        <button className="ql-underline" />
        <button className="ql-strike" style={{ marginRight: 30 }} />
        <select className="ql-align" defaultValue={""} onChange={e => e.persist()} style={{ border: 0 }}>
          <option />
          <option value="center" />
          <option value="right" />
          <option value="justify" />
        </select>
        <button className="ql-list" value="ordered" />
        <button className="ql-list" value="bullet" />
        <button className="ql-indent" value="+1" />
        <button className="ql-indent" value="-1" />
        <button className="ql-image" style={{ marginLeft: 30 }} />
        <button className="ql-link" style={{ marginRight: 30 }} />
      </div>
      {/* <Button text={<CloudDownloadOutlinedIcon style={{ fontSize: 18 }} />} primary onClick={() => exportReport()} style={{ marginRight: 10 }} /> */}
      {/* <Button text="Save Comment" style={{ marginLeft: 'auto' }} primary onClick={saveComment} /> */}
    </div>
  );
};

export default CommentToolbar;
