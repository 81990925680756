import React from 'react';
import './App.css';
import {
  Routes,
  Route,
  Navigate
} from 'react-router-dom';
import {
  SearchPage,
  LoginPage,
  NotFoundPage,
  LoadingPage,
  ReportsPage,
  ReportPage,
} from './pages';
import { userService, reportService } from './services';
import { useState, useEffect } from 'react';
import AppLayout from 'components/Layout';
import AppContext from './AppContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const [currentReport, setCurrentReport] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const getUser = () => {
    userService.getCurrentUser().then(user => {
      setCurrentUser(user);
      setLoading(false);
    });
  };

  const getActiveReport = () => {
    reportService.getLastActiveReport().then(data => {
      if (data.data.report) {
        setCurrentReport(data.data.report)
      } else {
        setCurrentReport(null)
      }
    })
  }

  useEffect(() => {
    getUser();
    getActiveReport();
  }, [currentUser?.email]);

  if (loading) return <LoadingPage />;

  return (
    <AppContext.Provider value={{ currentReport, setCurrentReport }}>
      <div className="App">
        {
          !currentUser || !currentUser.active
            ?
            /* UNPROTECTED ROUTES */
            <Routes>
              <Route path="*" element={<LoginPage onLogin={getUser} />} />
            </Routes>
            :
            /* PROTECTED ROUTES */
            <AppLayout currentUser={currentUser} onLogout={() => setCurrentUser(null)}>
              <Routes>
                <Route path='*' element={<NotFoundPage />} />
                <Route path="/" element={<Navigate to="/search" />} />
                <Route path="/search" element={<SearchPage />} />
                <Route path="/reports" element={<ReportsPage />} />
                <Route path="/reports/:id" element={<ReportPage />} />
              </Routes>
            </AppLayout>
        }
        <ToastContainer
          position="bottom-right"
          pauseOnFocusLoss={false}
          pauseOnHover={false}
          hideProgressBar={true}
        />
      </div>
    </AppContext.Provider>
  );
}

export default App;
