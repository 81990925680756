import axios from "axios";
import { authService } from "services";
import AppConfig from 'config';

const api = axios.create({
    baseURL: AppConfig.backendURL,
    withCredentials: true,
    timeout: 120000,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    }
});

api.interceptors.request.use(req => {
    if(authService.getAccessToken()) {
        req.headers["Authorization"] = `JWT ${authService.getAccessToken()}`;
    }
    return req;
});

api.interceptors.response.use((response) => {
    return response;
}, async function (error) {
    const originalRequest = error.config;
    if (error.response?.status === 403 && !originalRequest._retry) {
        if (authService.getRefreshToken()) {
            originalRequest._retry = true;
            const accessToken = await authService.refreshAccessToken();
            if (!accessToken) {
                axios.defaults.headers.common['Authorization'] = `JWT ${accessToken}`;
            }
            return api(originalRequest);
        }
    }
    return Promise.reject(error);
});


export default api;
