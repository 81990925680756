import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

const Popup = ({container, alterOverflow, children}) => {
    useEffect(() => {
        alterOverflow !== false && document.body.style.setProperty("overflow", "hidden");
        return () => {
            alterOverflow !== false && document.body.style.setProperty("overflow", "auto");
            document.body.removeChild(container);
        }
    }, [container, alterOverflow])

    return children;
}

const firePopup = (Element, alterOverflow) => {
    var div = document.createElement('div');

    const close = () => {
        ReactDOM.unmountComponentAtNode(div);
    };
    
    ReactDOM.render(
       <Popup container={div} alterOverflow={alterOverflow}>{React.cloneElement(Element, {close})}</Popup>,
       document.body.appendChild(div)
    );
};

Popup.firePopup = firePopup;

export default Popup;