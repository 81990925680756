import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { useNavigate, useParams, Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { reportService } from "services";
import useRequest from "hooks/useRequest";
import { Loader } from "components/Loader";
import Error from "components/Error";
import { useEffect } from "react";
import { ReportHeadings, ReportActiveToggler, ReportSourceSection } from "components/Reports";
import ReactTimeAgo from 'react-time-ago';
import AppConfig from 'config';
// import alertIcon from './icons/alert.svg'

// import ReportEditorToolbar from "components/Reports/ReportEditorToolbar";
import { formatToDate } from "utils";
import ReportActions from "components/Reports/ReportActions";
import { CHImg, DEImg, EUImg, FRImg, ITImg, USImg } from './icons/flags';
import redoPng from './icons/reload.png';
import { useQueryClient } from "react-query";
import { ResultContent } from "components/Reports/ReportSourceSection";

const alertIcon = <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M5.99999 9.28173C5.81879 9.28173 5.67188 9.13482 5.67188 8.95361C5.67188 8.7724 5.81879 8.62549 5.99999 8.62549" stroke="#FF0000" />
  <path d="M6 9.28173C6.18121 9.28173 6.32812 9.13482 6.32812 8.95361C6.32812 8.7724 6.18121 8.62549 6 8.62549" stroke="#FF0000" />
  <path d="M6 6.65645V4.03149" stroke="#FF0000" stroke-linecap="round" stroke-linejoin="round" />
  <path d="M11.0867 9.00304C11.2052 9.23972 11.2612 9.50274 11.2493 9.76716C11.2375 10.0316 11.1582 10.2886 11.0191 10.5138C10.88 10.7389 10.6855 10.9248 10.4543 11.0537C10.2231 11.1825 9.9628 11.2501 9.69812 11.25H2.30274C2.03806 11.2501 1.77775 11.1825 1.54658 11.0537C1.31532 10.9248 1.1209 10.7389 0.981777 10.5138C0.842654 10.2886 0.76338 10.0316 0.751568 9.76716C0.739668 9.50274 0.795668 9.23972 0.914141 9.00304L4.61183 1.60854C4.74071 1.3505 4.9389 1.13359 5.18416 0.981959C5.42942 0.830324 5.71204 0.75 6.00043 0.75C6.28883 0.75 6.57145 0.830324 6.8167 0.981959C7.06196 1.13359 7.26015 1.3505 7.38903 1.60854L11.0867 9.00304Z" stroke="#FF0000" stroke-linecap="round" stroke-linejoin="round" />
</svg>
const getCountryImgPath = (country) => {
  switch (country) {
    case 'CH':
      return CHImg;
    case 'DE':
      return DEImg;
    case 'IT':
      return ITImg;
    case 'US':
      return USImg;
    case 'EU':
      return EUImg;
    case 'FR':
      return FRImg;
    default:
      return CHImg

  }
}
const sources = AppConfig.sources;
const getSourceFromLabel = (label) => sources.find(f => f.label === label);
const orderCountsBySourcesConfig = (counts)=>{
  const ordered = sources.reduce((acc, source, index)=>{
    const count = Object.entries(counts).find(([src, count]) => src===source.label);
    if(count) return [...acc, count]
    return acc;
  },[])

  return ordered;
} 

const useStyles = makeStyles({
  root: {},
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #E3E3E3",
    position: 'sticky',
    top: '64px',
    zIndex: '1',
    backgroundColor: 'white',
    "@media print": {
      display: "none",
    },
  },
  headerLeft: {
    display: "flex",
    alignItems: "center",
    padding: "40px 24px",
    flex: 1,
    height: 40,
  },
  headerRight: {
    display: "flex",
    alignItems: "center",
    padding: "40px 24px",
    borderLeft: "1px solid #E3E3E3",
    height: 40,
  },
  backIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#CE2C3B",
    backgroundColor: "#FCEEF0",
    height: 40,
    width: 40,
    marginRight: 32,
    borderRadius: 8,
    cursor: "pointer",
  },
  headerTitle: {
    fontWeight: 700,
    fontSize: 16,
  },
  history: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "auto",
    textAlign: "right",
  },
  historyLastModified: {
    fontSize: 12,
    fontWeight: 400,
    color: "#5D6598",
    marginRight: 8,
  },
  historyShowHistory: {
    display: "flex",
    alignItems: "center",
    color: "#30354F",
    fontSize: 12,
    fontWeight: 700,
    cursor: "pointer",
    userSelect: "none",
  },
  container: {
    display: "flex",
    minHeight: "calc(100vh - 150px)",
    height: "100%",
    position: "relative",
  },
  pdfBtn: {
    position: 'fixed',
    left: "5%",
    bottom: 32,
    boxShadow: "0px 6px 27px rgba(0, 0, 0, 0.09)",
  },
  reportBody: {
    // padding: '8px 24px',
    width: "82%"

  },
  searchSection: {
    padding: '56px 120px',
    "&:nth-child(2n)": {
      backgroundColor: "#FFF",
    },
    "&:nth-child(2n+1)": {
      backgroundColor: "#F9F9F9",
    }
  },
  searchTitle: {
    fontWeight: '700',
    fontSize: '24px',
    lineHeight: '30px',
    margin: '0'
  },
  searchInfo: {
    padding: "10px 16px",
    border: '1px solid #000000',
    background: '#FFFFFF',
    borderRadius: '8px',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '18px',
    color: '#30354F'
  },
  searchInfoSubtitle: {
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '20px',
    color: '#6D6D6D',
    marginBlock: '24px 12px',
  },
  searchCount: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    background: '#FFFFFF',
    border: '1px solid #000000',
    borderRadius: '8px',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '15px',
    color: '#30354F',
    padding: '10px 12px',
  },
  flag: {
    width: '16px',
    height: '16px',
    display: 'flex',
    justifyContent: 'center'
  },
  alertIcon: {
    width: '11px',
    height: '11px',
    display: 'flex',
    justifyContent: 'center'
  },

  searchCountAlert: {
    border: '1px solid #FF0000',
    color: '#FF0000',
  },
  searchInfosContainer: {
    display: 'flex',
    gap: '8px',
    flexWrap: 'wrap',
    width: '100%',
  }
});

const Report = () => {
  const { id: reportId } = useParams();
  const [report, setReport] = useState(null);
  const queryClient = useQueryClient()

  // const reportResults = useGetReportResults(reportId);
  // const reportData = useGetReport(reportId)
  const { loading, error, data } = useRequest(() => {
    return reportService.getReport(reportId);
  }, [reportId]);

  const navigate = useNavigate();
  const classes = useStyles();

  useEffect(() => {
    setReport(data ? data.report : null);
    window.__report_title = data?.report?.name ?? '';
  }, [data]);

  if (loading) return <Loader />;

  if (error) return <Error title="Oh oh! We have encountered an error." />;

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.headerLeft}>
          <span className={classes.backIcon} onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </span>
          <span className={classes.headerTitle}>{report.title}</span>
          <div className={classes.history}>
            <span className={classes.historyLastModified}>
              Last modified <ReactTimeAgo date={new Date(report.updated_at)} />
            </span>
          </div>
        </div>
        <div className={classes.headerRight}>
          <ReportActiveToggler report={report} active={report.is_active} setActive={active => {
            setReport(r => ({ ...r, is_active: active }));
          }} />
        </div>
      </div>
      <div className={classes.container}>
        <ReportHeadings name={report.name} searches={report.searches} />
        <div id="report-body" className={classes.reportBody}>
          <GeneralInfos report={report} />
          {/* <ReportEditorToolbar report={report} /> */}
          <div id="report-searches-container">
            {report.searches.map((search, index) => {
              return <div key={search.id} id={`search-${index + 1}`} className={classes.searchSection}>
                <h2 className={classes.searchTitle}>Search {index + 1}</h2>
                <SearchInfos {...search} queryClient={queryClient} />
                {Object.entries(search.entries).filter(e => e[0] !== 'zefix').map(([source, results]) => {
                  return <ReportSourceSection key={source} searchId={search.id} source={source} results={results} />
                })}</div>
            }
            )}

          </div>
          <ReportActions />
        </div>
      </div>
    </div >
  );
};

export default Report;

const useGeneralInfosStyles = makeStyles({
  container: {
    padding: '8px 24px',
  },
  root: {
    fontFamily: 'Flink',
    width: '100%',
  },
  formLabel: {
    color: '#6D6D6D',
    fontSize: 14,
    fontWeight: 700,
  },
  formInput: {
    marginTop: 8,
    '& input, textarea': {
      border: '1px solid #E1E1E1',
      backgroundColor: '#fff',
      borderRadius: 8,
      color: '#30354F',
      fontSize: 14,
      padding: '8px 16px',
    },
    '& textarea': {
      width: '100%',
      resize: 'none',
    }
  },
  spacer: {
    paddingTop: 16,
  },
  checkboxContainer: {
    // flexGrow: 1
  },
  commentContainer: {
    flexGrow: 8,
    marginTop: 16,
  },
  zefixContainer: {
    marginBlock: 28
  },
  zefixDataContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: 6,
  },
  zefixDataValue: {
    fontWeight: 700,
  },
  zefixData: {
    backgroundColor: '#EBEBEB',
    borderRadius: 8,
    padding: '6px 8px',
    fontSize: 12,
    fontWeight: 400,
    color: '#30354F',
    fontStyle: 'normal',
    marginRight: 8,
    marginTop: 6,
    width: 'fit-content',
  },
  zefixTitle: {
    color: '#6D6D6D',
    fontSize: 14,
    fontWeight: 700,
  },
  checkboxLabelRoot: {
    display: 'inline-block'
  },
  checkboxLabel: {
    fontWeight: 700,
    fontSize: 12,
    color: '#30354F',
    display: 'inline',
  },
  selectBox: {
    marginTop: 8,
    border: '1px solid #E1E1E1',
    backgroundColor: '#fff',
    borderRadius: 8,
    color: '#30354F',
    fontSize: 12,
    padding: '8px 16px',
  },
  gIDateSpan: {
    fontWeight: 700,
    fontSize: 12,
    color: '#30354F',
  },
  headerTopContainer: {
    position:'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
  },
  saNumberInput: {
    border: 'none',
    fontSize: 18,
    color: '#30354F',
    outline: 'none',
    borderLeft: '5px solid rgb(206, 44, 59)',
    paddingLeft: 12,
    fontWeight: 700,
    lineHeight:'18px',
    height: '56px',
  },
  createdByInput: {
    fontSize: 14,
    fontWeight: 700,
    width: '6ch',
    color: '#30354F',
    border: 'none',
    outline: 'none',
  },
  headerContainer: {
    display: 'grid',
    gridTemplateColumns: "1fr 1fr",
    gridTemplateRows: "1fr auto",
    gap: '16px 28px',
    '& > div:first-of-type': {
      borderRight: "1px solid #CCC",
      paddingRight: 28
    },
    '& > div:last-of-type': {
      gridColumn: "1/-1",
    }

  },
  inlineInputs: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  dateContainer: {
    marginLeft: 'auto',
  },
  commentArea: {
    minHeight: 56,
    border: '1px solid #ECEDF3 !important',
    backgroundColor: '#fff',
    borderRadius: 0,
    color: '#30354F',
    fontSize: 12,
    padding: '8px 16px',

  },
  multiChoices:{
    marginBlock:'28px',
    '& label':{
      color: '#30354F',
      fontSize: '14px',
      fontWeight: '700',
      '&:not(:first-of-type)':{
        marginLeft: '8px'
      },
    },
  }
});

const MEP_CHOICES = [
  ['I', 'i'],
  ['Is', 'is'],
  ['Im', 'im'],
  ['Ir', 'ir'],
  ['IIa', 'iia'],
  ['IIb', 'iib'],
  ['III', 'iii'],
]
const IVDV_CHOICES = [
  ['A', 'a'],
  ['B', 'b'],
  ['C', 'c'],
  ['D', 'd'],
  ['List A', 'list a'],
  ['List B', 'list b'],
  ['Other', 'other'],
]

const GeneralInfos = ({ report }) => {

  const classes = useGeneralInfosStyles();

  const [ values, setValues] = useState({
    report_id: report.id,
    date: formatToDate(report.created_at, 'DD/MM/YYYY'),
    manufacturer: report.manufacturer,
    device: report.device,
    appRisks: report.risks,
    mepIvdv: report.mep_ivdv,
    createdBy: report.created_by,
    manufacturer_contact: report.manufacturer_contact,
    information_source: report.message_source,
    comment: report.summary,
    sa_number: report.sa_number,
  });

  let zefixReg = null
  if (report.zefix_details && report.zefix_details.length > 0) {
    zefixReg = report.zefix_details[0]
  }

  function autoHeightResize(e) {
    e.target.style.removeProperty('height');
    e.target.style.height = (e.target.scrollHeight + 2) + 'px';
  }

  const saveMepIvdv = value => (e) => {
    setValues({ ...values, mepIvdv: value });
    reportService.saveGeneralInformations({
      ...values,
      risks: values.appRisks,
      message_source: values.information_source,
      summary: values.comment,
      mepIvdv: value
    });
  };

  return (
    <div id="report-header" className={classes.container}>
      <div className={classes.headerTopContainer}>
        <input className={classes.saNumberInput} placeholder="SA Number" type="text" value={values.sa_number} onChange={(e) => {
          setValues({
            ...values,
            sa_number: e.target.value,
          });
        }} onBlur={() => {

          reportService.saveGeneralInformations({
            ...values,
            risks: values.appRisks,
            message_source: values.information_source,
            summary: values.comment,
            sa_number: values.sa_number,
          });
        }} autoFocus="autofocus" />
        <span>
          <span className={classes.formLabel}>Erstellt am: </span>
          <span className={classes.gIDateSpan}>{values.date}</span>
          <span className={classes.formLabel}>&emsp;von: </span>
          <input className={classes.createdByInput} placeholder="name" type="text" 
          value={values.createdBy}
          onChange={(e) => {
            setValues({
              ...values,
              createdBy: e.target.value,
            });
          }} onBlur={() => {
  
            reportService.saveGeneralInformations({
              ...values,
              risks: values.appRisks,
              message_source: values.information_source,
              summary: values.comment,
              createdBy: values.createdBy,
            });
          }} autoFocus="autofocus"
          />
        </span>
      </div>
      <div className={classes.headerContainer}>
        <div className={classes.headerLeft}>
          <div>
              <span className={classes.formLabel}>Hersteller</span>
              <div className={classes.formInput}>
                <input type="text" value={values.manufacturer} style={{width:'100%'}} 
                 onChange={(e) => {
                  setValues({
                    ...values,
                    manufacturer: e.target.value,
                  });
                }} onBlur={() => {

                  reportService.saveGeneralInformations({
                    ...values,
                    risks: values.appRisks,
                    message_source: values.information_source,
                    summary: values.comment,
                  });
                }} />
              </div>
          </div>
          {zefixReg && (<div className={classes.zefixContainer}>
            <span className={classes.zefixTitle}>ZEFIX informations</span>
              <div className={classes.zefixData}>
                <span className={classes.zefixDataValue}>
                <a href={`${zefixReg.zefixDetailWeb?.de? zefixReg.zefixDetailWeb?.de:Object.values(zefixReg.zefixDetailWeb)?.[0]}`} target="_blank" rel="noreferrer">
                  <span className={classes.zefixDataValue}>{zefixReg.name}</span>
                </a>
                  
                  </span>
              </div>
              <div className={classes.zefixDataContainer}>
                <div className={classes.zefixData}>
                  <span className={classes.zefixDataValue}>{zefixReg.address.street} {zefixReg.address.houseNumber}</span>
                </div>
                <div className={classes.zefixData}>
                  <span className={classes.zefixDataValue}>{zefixReg.address.city} {zefixReg.address.swissZipCode}</span>
                </div>
                <div className={classes.zefixData}>
                  <span className={classes.zefixDataValue}>{(zefixReg.sogcPub && zefixReg.sogcPub.length > 0) ? zefixReg.sogcPub[0].registryOfCommerceCanton : 'N/A'}</span>
                </div>
              </div>
              <div className={classes.zefixData}>
                <span className={classes.zefixDataLabel}>UID : </span>
                <a href={`https://www.uid.admin.ch/Detail.aspx?uid_id=${zefixReg.uid}`} target="_blank" rel="noreferrer">
                  <span className={classes.zefixDataValue}>{zefixReg.uid}</span>
                </a>
              </div>
              {/* <div className={classes.zefixData}>
                <span className={classes.zefixDataLabel}>Auditors : </span>
                <span className={classes.zefixDataValue}>{(zefixReg.auditCompanies && zefixReg.auditCompanies.length > 0) ? zefixReg.auditCompanies.map(c => ({ name: c.name, uid: c.uid })).map((c, i) => (
                  <span>
                    <a href={`https://www.zefix.admin.ch/de/search/entity/list?name=${c.uid}&directLink=true`} target="_blank" rel="noreferrer">{c.name}</a>
                    {i < zefixReg.auditCompanies.length - 1 ? ', ' : ''}
                  </span>
                )) : 'N/A'}</span>
              </div> */}
          </div>)}

          <div>
            <span className={classes.formLabel}>Herstellerwebseite, Kontakt lt. Impressum</span>
            <div className={classes.formInput}>
              <textarea type="text" value={values.manufacturer_contact}
                onFocus={autoHeightResize}
                onChange={(e) => {
                  autoHeightResize(e)
                  setValues({
                    ...values,
                    manufacturer_contact: e.target.value,
                  });
                }} onBlur={() => {

                  reportService.saveGeneralInformations({
                    ...values,
                    risks: values.appRisks,
                    message_source: values.information_source,
                    summary: values.comment
                  });
                }}
                autoFocus
              />
            </div>
          </div>

        </div>
        <div className={classes.headerRight}>

            <div>
              <span className={classes.formLabel}>Produkt/Device</span>
              <div className={classes.formInput}>
                <input type="text" value={values.device} style={{width:'100%'}}
                 onChange={(e) => {
                  setValues({
                    ...values,
                    device: e.target.value,
                  });
                }} onBlur={() => {

                  reportService.saveGeneralInformations({
                    ...values,
                    risks: values.appRisks,
                    message_source: values.information_source,
                    summary: values.comment
                  });
                }} />
              </div>
            </div>
            <div className={classes.multiChoices}>
              <div className={classes.formLabel} style={{marginBottom: '8px'}}>MEP</div>
                {MEP_CHOICES.map(([name,value])=>{
                  return (<label>
                    <input type='radio' name='mep-ivdv' value={value} 
                      checked={values.mepIvdv===value}
                      onChange={saveMepIvdv(value)} 
                    />
                    {name}
                  </label>)
                })}
            </div>
            <div className={classes.multiChoices}>
              <div className={classes.formLabel} style={{marginBottom: '8px'}}>IVDV</div>
                {IVDV_CHOICES.map(([name,value])=>{
                  return (<label>
                    <input type='radio' name='mep-ivdv' value={value} 
                      checked={values.mepIvdv===value}
                      onChange={saveMepIvdv(value)} 
                    />
                    {name}
                  </label>)
                })}
            </div>
          <div>
            <span className={classes.formLabel}>Anwendung / Risiken</span>
            <div className={classes.formInput}>
              <textarea type="text" value={values.appRisks}
                onFocus={autoHeightResize}
                onChange={(e) => {
                  autoHeightResize(e)
                  setValues({
                    ...values,
                    appRisks: e.target.value,
                  });
                }} onBlur={() => {

                  reportService.saveGeneralInformations({
                    ...values,
                    risks: values.appRisks,
                    message_source: values.information_source,
                    summary: values.comment
                  });
                }}
                autoFocus />
            </div>
          </div>


        </div>
        <div className={classes.headerBottom}>
          <div>
            <span className={classes.formLabel}>Quelle der Meldung / Informationen über den Melder</span>
            <div className={classes.formInput} style={{ paddingRight: 45 }}>
              <textarea style={{ width: "50%" }} type="text" value={values.information_source}
                onFocus={autoHeightResize}
                onChange={(e) => {
                  autoHeightResize(e)
                  setValues({
                    ...values,
                    information_source: e.target.value,
                  });
                }} onBlur={() => {

                  reportService.saveGeneralInformations({
                    ...values,
                    risks: values.appRisks,
                    message_source: values.information_source,
                    summary: values.comment
                  });
                }}
                autoFocus
              />
            </div>
          </div>


          <div className={classes.commentContainer}>
            <span className={classes.formLabel}>Comment</span>
            <div className={classes.formInput} style={{ paddingRight: 45 }}>
            <textarea type="text" value={values.comment}
                onFocus={autoHeightResize}
                onChange={(e) => {
                  autoHeightResize(e)
                  setValues({
                    ...values,
                    comment: e.target.value,
                  });
                }} 
                onBlur={() => {
                  reportService.saveGeneralInformations({
                    ...values,
                    risks: values.appRisks,
                    message_source: values.information_source,
                    summary: values.comment
                  });
                }}
                autoFocus
              />
            </div>
          </div>
        </div>
      </div>
      {zefixReg?.['non_editable_html'] && <ResultContent content={zefixReg['non_editable_html']}/>}
    </div>
  )
}

const SearchInfos = ({ id, manufacturer, device, date_min, date_max, counts, queryClient }) => {
  const classes = useStyles();
  const [resultsCounts, setResultsCounts] = useState([]);

  useEffect(()=>{
    setResultsCounts(orderCountsBySourcesConfig(JSON.parse(counts)))
  },[counts])

  const params = {
    ...(manufacturer && { applicant: manufacturer }),
    ...(device && { device }),
    ...(date_min && { from: date_min }),
    ...(date_max && { to: date_max }),
    ...(id && { id })
  }
  const urlParams = new URLSearchParams(params);

  return <div>
    <div>
      <div className={classes.searchInfoSubtitle}>Research informations</div>
      <div className={classes.searchInfosContainer}>
        <SearchInfo title="Manufacturer" value={manufacturer} />
        <SearchInfo title="Product form" value={device} />
        <SearchInfo title="From" value={date_min ? formatToDate(date_min, 'DD-MM-YYYY') : 'N/A'} />
        {date_max && <SearchInfo title="To" value={formatToDate(date_max, 'DD-MM-YYYY')} />}
        <div className="do-not-print" style={{
          display: 'flex',
          alignItems: 'center',
        }}>
          <Link 
            to={`/search?${urlParams.toString()}`} 
            onClick={()=>{
              queryClient.setQueryData("__selected_filters__", undefined)
            }}
            style={{
              backgroundColor: '#27ae60',
              borderRadius: 5,
              color: 'white',
              fontWeight: 'bold',
              padding: '4px 15px',
            }}
          >
            <img height={24} src={redoPng} alt="redo" style={{
              verticalAlign: 'middle',
            }} />
            <span style={{
              verticalAlign: 'middle',
              marginLeft: 8,
              fontSize: 14
            }}>Re-Do</span>
          </Link>
        </div>
      </div>
    </div>
    <div>
      <div className={classes.searchInfoSubtitle}>Sources hits</div>
      <div className={classes.searchInfosContainer}>{resultsCounts.map(([source, count]) => {
        return <SearchCount key={source} source={source} count={count} />
      })}</div>
    </div>
  </div>
}

const SearchInfo = ({ title, value }) => {
  const classes = useStyles();
  return <span className={classes.searchInfo}>
    <span>{title} :</span>
    <strong> {value}</strong>
  </span>
}

const SearchCount = ({ source, count }) => {
  const classes = useStyles();
  const { name, countryCode } = getSourceFromLabel(source);
  // const imgPath = require(`assets/flags/${countryCode}.svg`);
  let img = getCountryImgPath(countryCode)

  if (count > 0) {
    return <span className={classes.searchCount}>
      {/* <img src={imgPath} alt='flag' /> */}
      <span className={classes.flag}>{img}</span>

      <span>{name} :</span>
      <strong>{count}</strong>
    </span>
  }

  if (count === -1) {
    return <span className={classes.searchCount + " " + classes.searchCountAlert}>
      {/* <img src={imgPath} alt='flag' /> */}
      <span className={classes.flag}>{img}</span>
      <span>{name} </span>
      <span className={classes.alertIcon}>{alertIcon}</span>
      {/* <img src={alertIcon} alt='source was down' /> */}
    </span>
  }

  return null
}
