import { saluteResultsAdapter, saluteAvvisiResultsAdapter } from "adapters";
import { useQuery } from "react-query";
import { client, formatToFDADate } from "utils";
import AppConfig from 'config';

export function useSaluteScrapper(values={}, page, shouldBeFetched) {
    const { applicant="", device="", from, to } = values
    const params = {
      applicant: applicant.trim().replaceAll("\"", ""),
      device: device.trim().replaceAll("\"", ""),
      page: page.number,
      size: page.size,
      from: formatToFDADate(from),
      to: formatToFDADate(to)
    };
    const queryString = new URLSearchParams(params).toString();
    const url = `${AppConfig.backendURL}/api/salute_search/?${queryString}`;
    const label = "salute";

    return useQuery({
        queryKey: ['searchSaluteScrapper', applicant, device, from, to , page.number, page.size],
        queryFn: ({signal}) => {
            let data = []
            if(!applicant && !device){
                data.total = {
                    [label]: 0
                }
                return data
            }
            return client(url,{signal})
            .then((res)=>{
                return saluteResultsAdapter(res.data, label);
            })
            .catch((err) => {
                console.error(err.name + ": " + err.message);
                data = [];
                data.total = {
                    [label]: -1
                };
                return data
            })
        },
        enabled:shouldBeFetched(label),
    });
}

export function useSaluteAvvisiScrapper(values={}, page, shouldBeFetched) {
    const { applicant="", device="", from, to } = values
    const params = {
      applicant: applicant.trim().replaceAll("\"", ""),
      device: device.trim().replaceAll("\"", ""),
      page: page.number,
      size: page.size,
      from: from ? (new Date(from).getFullYear()) : "",
      to: to ? (new Date(to).getFullYear()) : "",
    };
    const queryString = new URLSearchParams(params).toString();
    const url = `${AppConfig.backendURL}/api/salute_avvisi_search/?${queryString}`;
    const label = "salute_avvisi";

    return useQuery({
        queryKey: ['searchSaluteAvvisiScrapper', applicant, device, from, to , page.number, page.size],
        queryFn: ({signal}) => {
            let data = []
            if(!applicant && !device){
                data.total = {
                    [label]: 0
                }
                return data
            }
            return client(url,{signal})
            .then((res)=>{
                return saluteAvvisiResultsAdapter(res.data, label);
            })
            .catch((err) => {
                console.error(err.name + ": " + err.message);
                data = [];
                data.total = {
                    [label]: -1
                };
                return data
            })
        },
        enabled:shouldBeFetched(label),
    });
}
