import { makeStyles } from "@mui/styles";
import React, { useContext } from 'react';
import webpageIcon from './icons/webpage.svg';
import pdfIcon from './icons/pdf.svg';
import copyIcon from './icons/copy.svg';
import saveIcon from './icons/save.svg';
import deleteIcon from './icons/delete.svg';
import { reportService } from 'services';
import AppContext from "AppContext";
import { toast } from 'react-toastify';
import { copyToClipboard } from "components/CopyToClipboard";

const useStyles = makeStyles({
  container: {
    position: 'relative',
    background: "#FFFFFF",
    border: "1px solid #ECEDF3",
    boxSizing: "border-box",
    boxShadow: "0px 2px 6px rgba(59, 100, 241, 0.25)",
    borderRadius: "16px",
    width: "100%",
    padding: "26px 32px",
    marginBottom: "16px",
    display: "flex",
  },
  main: {
    flex: 1,
    maxWidth: "calc(100% - 32px*3)",
  },
  menu: {
    marginLeft: "auto",
    display: "flex",
    gap: 12,
    marginBlock: 'auto',
  },
  header: {
    display: "flex",
    gap: "16px",
    alignItems: "center",
    marginBottom: "8px"
  },
  title: {
    display: "block",
    textAlign: "start",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "18px",
    textDecoration: "none",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  date: {
    fontFamily: "Flink",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    color: "#5D6598"
  },
  type: {
    height: "24px",
    background: "#E1E3EF",
    borderRadius: "24px",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "12px",
    display: "flex",
    gap: "8px",
    alignItems: "center",
    color: "#414766",
    padding: "0px 9px 0px 2px"
  },
  description: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    display: "block",
    textAlign: "start",
    lineHeight: "18px",
    color: "#858585",
    margin: "0"
  },
  actionBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 32,
    height: 32,
    cursor: 'pointer',
    background: '#F1F1F1',
    borderRadius: "6px",
    border: 'none',
  },
  descriptionElemBlock: {
    marginBottom: 10,
  },
  descriptionBlockContainer: {
    display: 'flex',
    fontSize: 12,
    justifyContent: 'space-between',
  }
});

const SearchResultsItem = ({ title, description, date = null, link, mime, type, swissmedic, salute, source, currentSearchId, actions = ["showModal", "save", "copy"], onDelete, children }) => {
  const classes = useStyles();
  const { currentReport } = useContext(AppContext);

  return (
    <div className={classes.container}>
      <div className={classes.main}>
        <div className={classes.header}>
          <a
            className={classes.title}
            href={link}
            title={title}
            target="_blank"
            rel="noreferrer"
          >
            {title}
          </a>
          <DocumentType mime={mime} />
          <span className={classes.date}>{date}</span>
        </div>
        {type !== 'swissmedic' ? (type !== 'salute' ? (
          <p className={classes.description}>{description}</p>
        ) : (
          <div>
            <div className={classes.descriptionBlockContainer}>
              <div className={classes.descriptionBlock}>
                <h4>Fabricant</h4>
                <div>{salute.fabricant}</div>
              </div>
              <div className={classes.descriptionBlock}>
                <h4>Commercial name</h4>
                {salute.devices.map((d,i) => (
                  <div key={i} className={classes.descriptionElemBlock}>
                    {d}
                  </div>
                ))}
              </div>
              <div className={classes.descriptionBlock}>
                <h4>Initial validation date</h4>
                <div>
                  {salute.intialValidationDate}
                </div>
              </div>
              <div className={classes.descriptionBlock}>
                <h4>Publication date</h4>
                <div>
                  {salute.publicationDate}
                </div>
              </div>
            </div>
            <div className={classes.descriptionBlock} style={{fontSize: 12}}>
              <h4>CND</h4>
              <div className={classes.descriptionElemBlock}>
                {salute.description}
              </div>
            </div>
          </div>
        )) : (
          <div className={classes.descriptionBlockContainer}>
            <div className={classes.descriptionBlock}>
              <h4>Fabricant</h4>
              <div>{swissmedic.fabricant}</div>
            </div>
            <div className={classes.descriptionBlock}>
              <h4>Commercial name</h4>
              {swissmedic.devices.map(d => (
                <div key={d.handelsname} className={classes.descriptionElemBlock}>
                  {d.handelsname} <br />
                  Model: {d.model} <br />
                  Lots: {d.lot}
                </div>
              ))}
            </div>
            <div className={classes.descriptionBlock}>
              <h4>Product description</h4>
              {swissmedic.devices.map((d, i) => (
                <div key={i} className={classes.descriptionElemBlock}>
                  {d.beschreibungKlasse}
                </div>
              ))}
            </div>
            <div className={classes.descriptionBlock}>
              <h4>Documents</h4>
              {swissmedic.documents.map((d, i) => (
                <div key={d.title}>
                  <a href={`https://fsca.swissmedic.ch/mep/api/publications/${swissmedic.reference}/documents/${i}`}>{d.title}</a>
                </div>
              ))}
            </div>
          </div>
        )}

      </div>
      <div className={classes.menu + ' do-not-print'}>
        {children}
        <div className={classes.menu} >
        {currentReport && (actions.includes("save") && <button className={classes.actionBtn} onClick={(e) => {
                reportService.saveLinkToActiveReport(currentReport.id, [{
                  title,
                  description,
                  date: new Date(date).toISOString(),
                  link,
                  source,
                  comment: '',
                  non_editable_html: '',
                  searchId: currentSearchId,

                }]).then((data) => {
                  if (data.data.success) {
                    toast.success("Company saved into active report!", { autoClose: 2000 });
                  }
                }).catch(() => {
                  toast.error("Couldn't save the report.");
                })
                e.preventDefault();
              }}>
                <img src={saveIcon} alt="save" />
              </button>)}

              {actions.includes("copy") && <button className={classes.actionBtn} onClick={() => {
                copyToClipboard({ title, link, date, description }, toast);
              }}>
                <img src={copyIcon} alt="copy" />
              </button>}

              {actions.includes("delete") && <button className={classes.actionBtn} onClick={onDelete}>
                <img src={deleteIcon} alt="delete" />
              </button>}
            </div>
      </div>
    </div>
  );
};

export default SearchResultsItem;

const DocumentType = ({ mime }) => {
  const classes = useStyles();
  const type = mime?.split('/')?.[1]?.toLowerCase();

  return <span className={classes.type}>
    <img src={type === 'pdf' ? pdfIcon : webpageIcon} alt='document type' />
    <span>{type === 'pdf' ? 'PDF' : 'Webpage'}</span>
  </span>;
}
