export default {
  backendURL: process.env.REACT_APP_BACKEND_URL || document.location.origin,
  gcApiKey: process.env.REACT_APP_GCAPIKEY,
  cx: process.env.REACT_APP_SEARCH_ENGINE_ID,
  switzerlandCx: process.env.REACT_APP_SWITZERLAND_SEARCH_ENGINE_ID,
  limitations: {
    fda_scrapper: [
      'Maximum 500 results',
      'Setting only “Max date” does not work on 510k and PMA (works in MAUDE)'
    ],
    google: [
      'Maximum 10 entries per page',
      'Maximum 10 pages',
      'Unknown delay between the release of a new article and its indexation in Google'
    ],
    salute_scrapper: [
      'Up to 1 week delay'
    ],
  },
  sources: [
    {label:'zefix', name:'ZEFIX', countryCode:'CH', source:'zefix', provider:'ZEFIX'},
    {label:'opendata_swiss', name:'Opendata.Swiss', countryCode:'CH', source:'opendata_swiss', provider:'OPENDATASWISS'},
    {label:'swissmedic_fscas', name:'FSCAs', countryCode:'CH', source:'swissmedic_scrapper', provider:'Swissmedic'},
    {label:'eudamed_economic_operators', name:'Economic Operators', countryCode:'EU', source:'eudamed_eo_scrapper', provider:'Eudamed'},
    {label:'eudamed_devices_spps', name:'Devices and SPPs', countryCode:'EU', source:'eudamed_devices_spps_scrapper', provider:'Eudamed'},
    {label:'eudamed_certificates', name:'Certificates', countryCode:'EU', source:'eudamed_certificates_scrapper', provider:'Eudamed'},
    {label:'bfarm_medizinprodukte', name:'Medical Devices', countryCode:'DE', source:'google', provider:'Bfarm'},
    {label:'bfarm_measures', name:'Measures from manufacturers', countryCode:'DE', source:'google', provider:'Bfarm'},
    {label:'bfarm_newsletter', name:'Newsletter', countryCode:'DE', source:'google', provider:'Bfarm'},
    {label:'bfarm_medizinprodukte_r3', name:'Medical Devices R3', countryCode:'DE', source:'google', provider:'Bfarm'},
    {label:'ansm', name:'ANSM', countryCode:'FR', source:'google', provider:'ANSM'},
    {label:'salute', name:'Salute.gov.it', countryCode:'IT', source:'salute_scrapper', provider:'ITA'},
    {label:'salute_avvisi', name:'Salute.gov.it avvisi', countryCode:'IT', source:'salute_avvisi_scrapper', provider:'ITA'},
    {label:'510k', name:'510k', countryCode:'US', source:'fda_scrapper', provider:'FDA'},
    {label:'maude', name:'Maude', countryCode:'US', source:'fda_scrapper', provider:'FDA'},
    {label:'pma', name:'PMA', countryCode:'US', source:'fda_scrapper', provider:'FDA'},
    {label:'recalls', name:'Device Recalls', countryCode:'US', source:'fda_scrapper', provider:'FDA'},
    {label:'warning_letters', name:'Warning Letters', countryCode:'US', source:'fda_scrapper', provider:'FDA'},
    {label:'pubmed', name:'PubMed', countryCode:'US', source:'pubmed_scrapper', provider:'PubMed'},
    {label:'switzerland_region_search', name:'Google CH', countryCode:'CH', source:'switzerland_google', provider:'GOOGLE CH'},
  ],
  disableMSAuth: process.env.REACT_APP_MSAUTH_DISABLE || "false"
};
