import React, { useReducer, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Sidebar from '../Popups/Sidebar';
import CloseIcon from '@mui/icons-material/Close';
import LoopIcon from '@mui/icons-material/Loop';
import Switch from 'components/Switch';
import Button from 'components/Button';
import { reportService } from 'services';

const useStyles = makeStyles({
  container: {
    margin: "40px 25px 40px 40px",
    paddingRight: 20,
    height: "80vh",
    minHeight: 500,
    overflow: 'auto',
  },
  header: {
    display: "flex",
    alignItems: "center",
  },
  title: {
    fontSize: 24,
    fontWeight: 700,
    color: "#000",
  },
  closeIcon: {
    marginLeft: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #000",
    height: 40,
    width: 40,
    borderRadius: 8,
  },
  subtitle: {
    display: "block",
    marginTop: 0,
    color: "#707590",
    fontWeight: 700,
    fontSize: 16,
  },
  inputGroup: {
    display: "block",
    marginTop: 30,
    marginRight: 20,
  },
  inputLabel: {
    fontSize: 14,
    fontWeight: 700,
    color: "#30354F",
    marginBottom: 16,
    display: "block",
  },
  errorMessage: {
    fontSize: 10,
    color: "#CE2C3B",
  },
  generalErrorMessage: {
    display: "block",
    marginTop: 30,
    fontWeight: 700,
    fontSize: 12,
    color: "#CE2C3B",
  },
  inputText: {
    fontFamily:'sans-serif',
    borderRadius: 6,
    border: "1px solid #A6ABC9",
    outline: 0,
    padding: "10px 15px",
    fontSize: 14,
    fontWeight: 400,
    width: "100%",
    maxWidth: "100%",
    minWidth: "100%",
  },
  switchGroup: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: 30,
  },
  footer: {
    borderTop: "1px solid #ececec",
    position: "sticky",
    bottom: 0,
    left: 0,
    right: 0,
    padding: 0,
    backgroundColor: "#fff",
  },
  actions: {
    padding: "30px 40px 15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "right",
  },
});

const NewReport = (props) => {
  const classes = useStyles();

  const [inputValues, setInputValues] = useReducer(
    (state, newState) => ({ ...state, ...newState }), {
    title: "",
    summary: "",
    message_source: "",
    sa_number: "",
    active: false,
  }
  );
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState({});


  const onInputChange = (e) => {
    const { name, value } = e.target;
    setInputValues({ [name]: value });
  };

  const onCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setInputValues({ [name]: checked });
  };

  const submit = () => {
    if (submitting) return;
    setSubmitting(true);

    const errors = {};

    // check if the form has errors
    if (inputValues.title.length === 0) errors["title"] = "Mandatory field.";

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      setSubmitting(false);
      return;
    }

    // form is valid
    setErrors({});

    // TODO: submit the form details
    reportService.createReport(inputValues).then(res => {
      // close the sidebar
      props.onReportAdded({ ...inputValues, id: res.data.id });
      props.close();
    }).catch(() => {
      setErrors({ "error": "Oops, something went wrong! Please retry." });
    }).finally(() => setSubmitting(false));
  };

  return (
    <Sidebar close={props.close}>
      <div className={classes.container}>
        <div className={classes.header}>
          <h3 className={classes.title}>Create a new report</h3>
          <span className={classes.closeIcon}><CloseIcon /></span>
        </div>
        <span className={classes.subtitle}>Fill out the inputs to create a new report</span>
        <div className={classes.inputGroup} style={{ marginTop: 40 }}>
          <label className={classes.inputLabel}>Report name (medical device related) <span className={classes.errorMessage}>{errors["title"]}</span></label>
          <input type="text" className={classes.inputText} name="title" value={inputValues.title} onChange={onInputChange} />
        </div>
        <div className={classes.inputGroup} style={{ marginTop: 40 }}>
          <label className={classes.inputLabel}>Source of message<span className={classes.errorMessage}>{errors["massageSource"]}</span></label>
          <input type="text" className={classes.inputText} name="message_source" value={inputValues.massageSource} onChange={onInputChange} />
        </div>
        <div className={classes.inputGroup} style={{ marginTop: 40 }}>
          <label className={classes.inputLabel}>SA Number<span className={classes.errorMessage}>{errors["saNumber"]}</span></label>
          <input type="text" className={classes.inputText} name="sa_number" value={inputValues.sa_number} onChange={onInputChange} />
        </div>
        <div className={classes.inputGroup}>
          <label className={classes.inputLabel}>Comments/Summary <span className={classes.errorMessage}>{errors["summary"]}</span></label>
          <textarea type="text" className={classes.inputText} name="summary" value={inputValues.summary} onChange={onInputChange} rows={6}></textarea>
        </div>
        <div className={classes.switchGroup}>
          <Switch id="active-switch" name="active" checked={inputValues.active} onChange={onCheckboxChange} />
          <label for="active-switch" style={{ marginBottom: 10, marginLeft: 12 }} className={classes.inputLabel}>Define as active report</label>
        </div>
        <span className={classes.generalErrorMessage}>{errors["error"]}</span>
      </div>
      <div className={classes.footer}>
        <div className={classes.actions}>
          <Button text="Cancel" onClick={props.close} style={{ marginRight: 8 }} />
          <Button primary text={submitting ? <LoopIcon className='spin' style={{ fontSize: 18, padding: "0px 36px" }} /> : "Create report"} onClick={submit} />
        </div>
      </div>
    </Sidebar>
  )
};

export default NewReport;
