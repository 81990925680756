import { makeStyles } from "@mui/styles";
import ReactQuill from "react-quill";
import ErrorBoundary from "components/ErrorBoundary";
import { CommentToolbar } from ".";

const useStyles = makeStyles({
    sourceSection: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    sourceTitle: {
        fontWeight: '700',
        fontSize: '20px',
        lineHeight: '26px',
        textAlign: 'center',
        color: '#000000',
        marginBlock: '76px 44px',
        '&::before': {
            position: 'absolute',
            content: '" "',
            height: 1,
            width: 'calc(50% - 120px)',
            backgroundColor: '#000',
            top: 'calc(76px + 13px)',
            transform: 'translateX(calc(-100% - 32px))',
        },
        '&::after': {
            position: 'absolute',
            content: '" "',
            height: 1,
            width: 'calc(50% - 120px)',
            backgroundColor: '#000',
            top: 'calc(76px + 13px)',
            marginLeft: '32px',
        }
    },
    result: {
        padding: '8px 24px',
    },
    iframeContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        "& img": {
            maxWidth: "100%",
        }
    },
    comment: {
    },
    metaData: {
        '& strong': {
            textTransform: 'capitalize',
        }
    },
})

const Editor = ({ editorId, content, saveContent, children }) => {
    const classes = useStyles();

    const handleOnCommentBlur = (...args) => {
        saveContent(...args)
    }

    const handleCommentFocus = () => {
        // setEnabledComment(editorId)
    }

    return <ErrorBoundary>
        <div className={classes.comment}>
            <CommentToolbar toolbarId={editorId} saveComment={saveContent} />
            {/* <ReportEditorToolbar enabledComment={enabledComment} toolbarId={editorId} /> */}
            <ReactQuill
                theme="snow"
                placeholder="Write comment"
                value={content || " "}
                onFocus={handleCommentFocus}
                onBlur={handleOnCommentBlur}
                modules={{
                    toolbar: {
                        // container: "#comment-toolbar",
                        container: `#${editorId}`,
                    },
                    clipboard: {
                        matchVisual: false,
                    },
                    // imageResize: {
                    //     parchment: Quill.import('parchment'),
                    //     modules: ['Resize', 'DisplaySize']
                    // }
                }}
                formats={[
                    "header",
                    "font",
                    "size",
                    "bold",
                    "italic",
                    "underline",
                    "strike",
                    "blockquote",
                    "list",
                    "bullet",
                    "indent",
                    "link",
                    "image",
                    "color",
                    "align",
                ]}
            >
                {children}
            </ReactQuill>
        </div>
    </ErrorBoundary>
}

export default Editor;