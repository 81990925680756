import React from 'react';
import { makeStyles } from "@mui/styles";
import { reportService } from 'services';
import AppContext from "AppContext";
import { toast } from 'react-toastify';
import { copyToClipboard } from "components/CopyToClipboard";
import copyIcon from './icons/copy.svg';
import saveIcon from './icons/save.svg';
import deleteIcon from './icons/delete.svg';

const useStyles = makeStyles({
  container: {
    position: 'relative',
    background: "#FFFFFF",
    border: "1px solid #ECEDF3",
    boxSizing: "border-box",
    boxShadow: "0px 2px 6px rgba(59, 100, 241, 0.25)",
    borderRadius: "16px",
    height: "190px",
    width: "100%",
    padding: "24px 32px",
    marginBottom: "18px"
  },
  title: {
    display: "block",
    textAlign: "start",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "18px",
    marginBottom: "12px",
    textDecoration: "none",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  infoItem: {
    background: "#FFFFFF",
    border: "1px solid #A6ABC9",
    boxSizing: "border-box",
    borderRadius: "6px",
    height: "40px",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    display: "flex",
    alignItems: "center",
    color: "#30354F",
    padding: "0 16px 0 16px",
    '@media(max-width: 1500px)' : {
      fontSize: "14px",
    },
  },
  infoItemsList: {
    display: "flex",
    gap: "16px"
  },
  actionBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 32,
    height: 32,
    cursor: 'pointer',
    background: '#F1F1F1',
    borderRadius: "6px",
    border: 'none',
    marginLeft: 12,
    marginBlock: 'auto',
    float: 'right',
  },
});
const OpendataSwissResultsItem = ({ title, chrn, name, pocode, city, email, website, source, currentSearchId, type="searchResult", onDelete }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <a
        className={classes.title}
        href={website ? (website.includes('http') || website.includes('https')) ? website : `https://${website.replace(/\s/g, '')}` : '#'}
        title={name}
        target={website && '_blank'}
        onClick={(e) => {
          !website && e.preventDefault();
        }}
        rel="noreferrer"
      >
        {name}
        {type === "searchResult" ? (
          <React.Fragment>
            <AppContext.Consumer>
              {({ currentReport }) => {
                return currentReport && (<button className={classes.actionBtn} onClick={(e) => {
                  reportService.saveOpendataSwissToActiveReport(currentReport.id, [{
                    title: name,
                    chrn,
                    name,
                    pocode,
                    city,
                    email,
                    website,
                    url: `#${chrn}|${name}`,
                    source,
                    searchId: currentSearchId,
                  }]).then((data) => {
                    if (data.data.success) {
                      toast.success("Company saved into active report!", { autoClose: 2000 });
                    }
                  }).catch(() => {
                    toast.error("Couldn't save the report.");
                  })
                  e.preventDefault();
                }}>
                  <img src={saveIcon} alt="save" />
                </button>)
              }}
            </AppContext.Consumer>
            <button className={classes.actionBtn} onClick={(e) => {
              e.preventDefault();
              copyToClipboard({ Title: name, Link: website, CHRN: chrn, 'POCode': pocode, 'City': city, 'Email': email, 'Website': website }, toast);
            }}>
              <img src={copyIcon} alt="copy" />
            </button>
          </React.Fragment>
        ) : (
          <button className={classes.actionBtn} onClick={(e) => {
            e.preventDefault();
            onDelete();
          }}>
            <img src={deleteIcon} alt="delete" />
          </button>
        )}
      </a>
      <div className={classes.infoItemsList}>
        <span className={classes.infoItem}>CHRN : {chrn}</span>
        <span className={classes.infoItem}>POCode : {pocode}</span>
        <span className={classes.infoItem}>City : {city}</span>
        <span className={classes.infoItem}>Email : {email}</span>
      </div>
      <div style={{marginTop: 15}} className={classes.infoItemsList}>
        <span className={classes.infoItem}>Website : {website ? (<a href={(website.includes('http') || website.includes('https')) ? website : `https://${website.replace(/\s/g, '')}`} rel="noreferrer" target="_blank">Link</a>) : 'N/A'}</span>
      </div>
    </div>
  );
};

export default OpendataSwissResultsItem;
