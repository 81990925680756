import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import deleteIcon from './icons/delete.svg';
import { Link } from "react-router-dom";
import ReactTimeAgo from 'react-time-ago';

const useStyles = makeStyles({
  card: {
    margin: 8,
    boxShadow: "0px 2px 6px rgba(59, 100, 241, 0.25)",
    padding: "20px 24px",
    backgroundColor: "#fff",
    border: "1px solid #ECEDF3",
    borderRadius: 8,
    cursor: "pointer",
  },
  header: {
    display: "flex",
  },
  title: {
    fontSize: 16,
    fontWeight: 700,
    color: "#30354F",
  },
  date: {
    marginLeft: "auto",
    color: "#5D6598",
    fontSize: 14,
    fontWeight: 500,
  },
  description: {
    color: "#30354F",
    fontSize: 14,
    fontWeight: 400,
    marginTop: 12,
    marginBottom: 12,
  },
  link: {
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    marginTop: 14,
    color: "#5D6598",
    fontWeight: 500,
  },
  withLinks: {
    color: "#0085FF",
  },
  linkIcon: {
    height: 16,
    width: 16,
    marginRight: 8,
  },
  descriptionDiv: {
    display: 'inline-block',
  },
  actionButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 32,
    height: 32,
    cursor: 'pointer',
    background: '#F1F1F1',
    borderRadius: "6px",
    border: 'none',
    float: 'right'
  }
});

const ReportCard = ({ report, onDelete }) => {
  const classes = useStyles();


  if (!report) return null;

  return (
    <Grid item xs={12} lg={6}>
      <Link to={`/reports/${report.id || ""}`}>
        <div className={classes.card}>
          <div className={classes.header}>
            <span className={classes.title}>{report.title}</span>
            <span className={classes.date}>
              <ReactTimeAgo date={report.date} />
            </span>
          </div>
          <div className={classes.description}>
            <div className={classes.descriptionDiv}>{report.summary}</div>
            <div onClick={(e) => {
              onDelete()
              e.preventDefault();
            }} className={classes.actionButton}>
              <img src={deleteIcon} alt="delete" />
            </div>
          </div>
        </div>
      </Link>
    </Grid>
  );
};

export default ReportCard;
