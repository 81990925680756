import searchIcon from "./icons/search.svg";
import clearIcon from "./icons/clear.svg";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  searchInput: {
    position: "relative",
    "& img": {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      "&:first-child": {
        left: 30,
      },
      "&:last-child": {
        right: 24,
        cursor: 'pointer',
      }
    },
    "& input": {
      fontSize: '18px',
      fontWeight: 'bold',
      lineHeight: '23px',
      color: '#30354F',
      height: '80px',
      backgroundColor: '#FFFFFF',
      border: '1px solid #E3E3E3',
      outline: 'none',
      boxSizing: 'border-box',
      paddingLeft: 76,
      "&::placeholder": {
        fontWeight: 'normal',
        color: '#5D6598',
      }
    },
  },
})

const SearchInput = ({ inputRef, value, setValue, style, clearIconStyle, name, placeholder, width }) => {
  const classes = useStyles();
  const onChange = (e) => {
    const { target: { value } } = e;
    setValue(value)
  }

  return (<span className={classes.searchInput}>
    <img src={searchIcon} alt="search" />
    <input ref={inputRef} name={name} type="text" placeholder={placeholder} value={value} onChange={onChange} style={{ width, ...style }} autoComplete="off" />
    <img src={clearIcon} onClick={() => setValue("")} alt="clear search" style={{ display: value ? "" : "none", ...clearIconStyle }} />
  </span>)
}

export default SearchInput;