import { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Link, useLocation } from "react-router-dom";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { authService } from "services";
import avatarIcon from "./icons/avatar.svg";
import AppContext from "AppContext";
import { ReportLinkSmall } from "components/Reports";
// import InfoIcon from './icons/info.png';
// import { useGetLatestRelease } from "hooks/api/github";


const useStyles = makeStyles({
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "sticky",
    top: "0",
    zIndex: 90,
    paddingInline: "64px",
    height: 64,
    backgroundColor: "#FFF",
    borderBottom: "1px solid #E3E3E3",
    "@media print": {
      display: "none",
    },
  },
  headerRight: {
    display: "flex",
    alignItems: "center",
  },
  logo: {
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "30px",
    display: "inline",
    alignItems: "center",
    color: "#131520",
    margin: 0,
    textDecoration: "none",
    "& span": {
      color: "red",
      transform: "translate(1px,-3px)",
    },
  },
  nav: {
    fontWeight: "bold",
    lineHeight: "30px",
    color: "#131520",
    textDecoration: "none",
    display: "inline",
    marginLeft: 35
  },
  profile: {
    position: "relative",
  },
  avatar: {
    borderRadius: "50%",
    height: 32,
    weight: 32,
    cursor: "pointer",
  },
  reportLink: {
    borderLeft: "1px solid #E3E3E3",
    marginLeft: 20,
    paddingLeft: 60,
  },
});

const Header = ({ currentUser, onLogout }) => {
  const classes = useStyles();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  return (
    <div className={classes.header}>
      <div>
        <Link to="/" className={classes.logo}>
          <span>/</span>ScARA
        </Link>
        <Link to="/search" className={classes.nav}>Search</Link>
      </div>
      <div className={classes.headerRight}>
        <ClickAwayListener onClickAway={() => setIsMenuOpen(false)}>
          <div className={classes.profile}>
            <img
              className={classes.avatar}
              src={avatarIcon}
              alt="avatar"
              onClick={() => setIsMenuOpen((o) => !o)}
            />
          {isMenuOpen && <DropDownMenu {...{currentUser, isMenuOpen, onLogout}}/>}
          </div>
        </ClickAwayListener>
        {!location.pathname.includes("/reports/") && <AppContext.Consumer>
          {({ currentReport }) =>
            currentReport && (
              <div className={classes.reportLink}>
                <ReportLinkSmall
                  reportId={currentReport.id}
                  title={currentReport.title || currentReport.name}
                />
              </div>
          )}
        </AppContext.Consumer>}
      </div>
    </div>
  );
};

const useMenuStyles = makeStyles({
  menu: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    transform: "translateX(-100%)",
    background: "#FFFFFF",
    border: "1px solid #C4C4C4",
    boxSizing: "border-box",
    minWidth: 300,
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "20px",
    color: "#5D6598",
    "& > span": {
      padding: "9px 12px 9px 16px",
      height: 48,
      "&:last-child": {
        cursor: "pointer",
      },
      "&:not(:last-child)": {
        fontWeight: "normal",
        borderBottom: "1px solid #C4C4C4",
      },
    },
  },
  infoIconContainer: {
    position: "relative",
    display: "inline-block",
    "& img": {
      width: 18,
      cursor: "pointer",
      marginTop: 3,
      marginRight: 20,
    },
    "&>div": {
      visibility: "hidden",
      width: "max-content",
      maxWidth:300,
      backgroundColor: "black",
      color: "#fff",
      borderRadius: 6,
      padding: 15,
      whiteSpace: 'pre-wrap',
      textAlign:"left",
      /* Position the tooltip */
      position: "absolute",
      top: "100%",
      left: "0%",
      transform:"translateX(-50%)",
      marginLeft: -60,
      zIndex: 1,
    },
    "&:hover > div": {
      visibility: "visible",
    },
    "& ul": {
      listStyle: "circle",
      textAlign: "left",
    },
    "& ul li": {
      color: "#fff",
      display: "list-item",
      marginLeft: 15,
    },
  },
  releaseInfo:{
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center"
  }
});

const DropDownMenu = ({currentUser, onLogout})=>{
  const classes = useMenuStyles();
  // const { data: releaseInfo = {} }  = useGetLatestRelease()
  const deploymentDate = process.env.REACT_APP_BUILD_DATE;
  const version = process.env.REACT_APP_TAG_VERSION;

  return (
    <div className={classes.menu}>
      {currentUser?.email && (
        <span data-value="email">{currentUser.email}</span>
      )}
      <span className={classes.releaseInfo} data-value="release date">
        <span>
          Deployed at : {deploymentDate}
          {version && (
            <>
              <br />
              Version : {version}
            </>
          )}
        </span>

        {/* {releaseInfo.body && (
            <div className={classes.infoIconContainer}>
              <img src={InfoIcon} alt="Info" />
              <div className={classes.tooltipText}>{releaseInfo.body}</div>
            </div>
          )} */}
      </span>

      <span onClick={() => authService.logout(onLogout)}>Log-out</span>
    </div>
  );

}

export default Header;
