/* eslint-disable no-unused-vars */
import React, { useEffect, useReducer, useState } from 'react';
import AppConfig from 'config';
import dayjs from 'dayjs';
import ResearchSection from './ResearchSection';
import Filters from './Filters';
import SearchResults from './SearchResults';
import { useSearch, useTotalPages, useUpdateQueryStringValueWithoutNavigation } from 'hooks';
import { ReportsContainer } from '../../containers/ReportsContainer';
import { saveSearch } from 'services/report.service';
import { useQueryClient } from 'react-query';

const sources = AppConfig.sources;

const paginationInitialState = { number: 1, size: 10 };

function paginationReducer(state, action) {
  switch (action.type) {
    case 'init':
      return { ...state, number: 1 };
    case 'next':
      return { ...state, number: state.number + 1 };
    case 'previous':
      const number = state.number - 1 >= 1 ? state.number - 1 : 1;
      return { ...state, number };
    case 'number':
      return { ...state, number: action.number }
    case 'size':
      return { ...state, size: action.size }
    default:
      throw new Error("this action is not supported by the pagination reducer");
  }
}

// const DEFAULT_SELECTED_FILTERS = sources.slice(0, 5).map(s => s.label)
const DEFAULT_SELECTED_FILTERS = ["zefix"]

export default function Search() {

  const queryClient = useQueryClient()
  const [selectedFilters, setSelectedFilters] = useState(()=>queryClient.getQueryData("__selected_filters__") || DEFAULT_SELECTED_FILTERS)
  const [page, dispatchPagination] = useReducer(paginationReducer, paginationInitialState);
  const [formValues, setFormValues] = useState(() => {
    const searchParams = new URLSearchParams(window.location?.search);
    return Object.fromEntries([...searchParams.entries()])
  });
  const { results, totalResults, setTotalResults, areResultsLoading, isOneSourceFinished, submitted, setSubmited, fetchSwitzerlandResults, sourcesStatus } = useSearch(formValues, page, selectedFilters);
  const setValue = (name) => (value) => setFormValues((v) => ({ ...v, [name]: value }));
  const selectedSourcesTotalResults = Object.fromEntries(Object.entries(totalResults).filter(([label])=>selectedFilters.includes(label)))
  const totalPages = useTotalPages(page, selectedSourcesTotalResults);
  const [showReports, setShowReports] = useState(false);
  const [isSwitzerlandSelected, setIsSwitzerlandSelected] = useState(false);
  const [currentSearchId, setCurrentSearchId] = useState(() => {
    return +(new URLSearchParams(window.location.search).get('id'));
  });

  useUpdateQueryStringValueWithoutNavigation(formValues);

  useEffect(() => {
    setSubmited(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page.number, page.size])

  useEffect(() => {
    if (!formValues?.applicant && !formValues?.device) setShowReports(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues?.applicant, formValues?.device])

  useEffect(() => {
    dispatchPagination({ type: 'init' });
    if (!formValues?.from && !formValues?.to) {
      setSubmited(true)
    }
    if (formValues?.from || formValues?.to) {
      if (!formValues.to) {
        const to = dayjs().startOf("date").format("YYYY-MM-DD").toString();
        setValue("to")(to);
      }
      setSubmited(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues?.from, formValues?.to]);

  useEffect(() => {
    if (isSwitzerlandSelected) {
      fetchSwitzerlandResults()
    }
  }, [fetchSwitzerlandResults, isSwitzerlandSelected, submitted])

  useEffect(() => {
    const currentSearchParams = new URLSearchParams(window.location.search)
    if (!areResultsLoading && (formValues?.applicant || formValues?.device) && !currentSearchParams.get('id')) {
      saveSearch({
        device: formValues?.device || '', manufacturer: formValues?.applicant || '', date_min: formValues?.from ? new Date(formValues?.from).toISOString() : null,
        date_max: formValues?.to ? new Date(formValues?.to).toISOString() : new Date().toISOString(), counts: totalResults
      })
        .then((res) => {
          setCurrentSearchId(res.data.id)
          currentSearchParams.set('id', res.data.id)
          const newUrl = [window.location.pathname, currentSearchParams.toString()].join('?')
          window.history.replaceState(null, '', newUrl)

        })
        .catch()

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areResultsLoading])

  useEffect(()=>{
    try{
      queryClient.setQueryData("__selected_filters__", selectedFilters)
    }catch{}
  }, [queryClient, selectedFilters])

  return (
    <div style={{ paddingBottom: 30 }}>
      <ResearchSection
        {...{
          formValues,
          setFormValues,
          dispatchPagination,
          setSubmited: (v) => {
            setShowReports(false);
            setTotalResults({})
            setSubmited(v);
            setSelectedFilters(DEFAULT_SELECTED_FILTERS);
          },
        }}
      />
      {showReports ? <ReportsContainer />
        : <div style={{ display: "flex", justifyContent: "center" }}>
          <Filters
            {...{
              selectedFilters, setSelectedFilters, sources, totalResults, setValue, setIsSwitzerlandSelected, dispatchPagination, sourcesStatus
            }}
          />
          <SearchResults
            {...{
              results,
              totalResults,
              totalPages,
              selectedFilters,
              setSelectedFilters,
              sources,
              page,
              dispatchPagination,
              isOneSourceFinished,
              setSubmited,
              currentSearchId,
            }}
          />
        </div>
      }
    </div>
  );
}
