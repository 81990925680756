import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles({
  sidebar: {
    display: "block",
    backgroundColor: "#FFF6F8",
    padding: "30px 24px 24px",
    width: "18%",
    fontSize: 12,
    fontWeight: 700,
    minWidth: 250,
    "@media print": {
      display: "none",
    },
  },
  container: {
    display: "flex",
    flexDirection: "column",
  },
  heading: {
    color: "#CE2C3B",
    fontSize: 14,
    fontWeight: 700,
    marginTop: 20,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  subheading: {
    color: "#30354F",
    fontSize: 12,
    fontWeight: 700,
    marginTop: 10,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginLeft: 16,
  },
  searches: {
    display: 'block',
    paddingLeft: 40,
    marginTop: 10,
  },
  searchLink: {
    display: 'block',
    textDecoration: 'none',
    color: '#CE2C3B',
    padding: 3,
    '&:visited': {
      color: '#CE2C3B',
    }
  }
});

const ReportHeadings = ({ name, content, searches }) => {
  const classes = useStyles();

  return (
    <div className={classes.sidebar + ' do-not-print'}>
      <div className={classes.container}>
        <span className={classes.heading}>{name}</span>
        <div className={classes.searches}>
          {searches.map((s, i) => (
            <a key={i} className={classes.searchLink} href={`#search-${i + 1}`}>Search {i + 1}</a>
          ))}
        </div>
      </div>
    </div>
  )
};

export default ReportHeadings;
