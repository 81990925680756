import React from 'react';
import { makeStyles } from '@mui/styles';
import Switch from 'components/Switch';
import { reportService } from 'services';
import { useState } from 'react';
import { CircularProgress } from '@mui/material';
import { useContext } from 'react';
import AppContext from 'AppContext';

const useStyles = makeStyles({
  text: {
    fontSize: 14,
    fontWeight: 700,
    marginLeft: 16,
    cursor:'pointer',
  },
});

const ReportActiveToggler = ({
  report,
  active,
  setActive,
}) => {
  const {setCurrentReport} = useContext(AppContext)
  const classes = useStyles();
  const [toggling, setToggling] = useState(false);

  const onToggle = (e) => {
    const checked = e.target ? e.target.checked : null;
    if(checked === null) return;
    setToggling(true);

    reportService.toggleReportActivity(report.id, checked).then(() => {
      setActive(checked);
      setCurrentReport(checked?report:null)
    }).finally(() => setToggling(false));
  }

  return (
    <React.Fragment>
      { toggling ? <CircularProgress style={{height: 20, width: 20, color: "#5D6598"}} /> : <Switch id="active-toggler" checked={active} onChange={onToggle}/>}
      <label for="active-toggler" className={classes.text}>
        Define as active report
      </label>
    </React.Fragment>
  )
}

export default ReportActiveToggler;
