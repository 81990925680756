import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { CircularProgress } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useZefixSuggestions } from 'hooks/api';
import { useDebounce } from 'hooks/useDebounce';
import buildingIcon from "./icons/building.svg";
import searchIcon from "./icons/search.svg";

const useStyles = makeStyles({
  searchSuggestions: {
    position: 'absolute',
    zIndex: 2,
    top: 'calc(100% + 8px)',
    left: '0',
    right: '59%',
    backgroundColor: '#fff',
    margin: '0',
    padding: '0',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '6px',
    '& > span': {
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '12px',
      lineHeight: '15px',
      display: 'flex',
      alignItems: 'center',
      color: '#9FA1C2',
      marginBlock: '16px 12px',
      paddingLeft: '24px',
    },
    '& li': {
      display: 'flex',
      alignItems: 'end',
      fontSize: '16px',
      color: '#30354F',
      cursor: 'pointer',
      padding: '12px 24px',
      '&:hover': {
        backgroundColor: "#0084ff0d"
      },
      '& img': {
        marginRight: 16,
      }
    },
    '& div': {
      display: "flex",
      alignItems: "center",
      gap: '16px',
      height: 72,
      borderTop: '1px solid #E3E3E3',
      paddingLeft: '24px',
      cursor: 'pointer',
    }
  },
})

const ZefixSuggestions = ({
  applicantInputRef,
  manufacturer,
  setManufacturer,
}) => {
  const classes = useStyles();
  const [showSuggestions, setShowSuggestions] = useState(false);
  const {
    data: suggestitions,
    refetch: fetchZefixSuggestions,
    status: suggestionsStatus,
  } = useZefixSuggestions(manufacturer);
  const zefixDebounce = useDebounce(fetchZefixSuggestions, 300);

  useEffect(() => {
    if (applicantInputRef.current === document.activeElement) {
      if (manufacturer && showSuggestions) zefixDebounce();
      if (manufacturer && !showSuggestions) setShowSuggestions(true);
      if (!manufacturer && showSuggestions) setShowSuggestions(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manufacturer, applicantInputRef]);

  return (
    <ClickAwayListener onClickAway={() => setShowSuggestions(false)}>
      <ul
        className={classes.searchSuggestions}
        style={{ display: !showSuggestions && "none" }}
      >
        <span>SUGGESTED COMPANIES</span>
        {suggestionsStatus === "loading" ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress color="secondary" />
          </div>
        ) : Array.isArray(suggestitions) && suggestitions.length > 0 ? (
          suggestitions.slice(0, 6).map((name) => (
            <li
              key={name}
              onClick={() => {
                setManufacturer(name);
                setTimeout(() => {
                  setShowSuggestions(false);
                })
              }}
            >
              <img src={buildingIcon} alt="building" />
              {name}
            </li>
          ))
        ) : (
          <li>No suggestions found...</li>
        )}
        <div onClick={() => setShowSuggestions(false)}>
          <img src={searchIcon} alt="search" />
          Search<strong>"{manufacturer}"</strong>
        </div>
      </ul>
    </ClickAwayListener>
  );
};

export default ZefixSuggestions;