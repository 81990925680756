import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import { ActiveReports, SavedReports } from 'containers/ReportsContainer';
import useSearchQuery from 'hooks/useSearchQuery';

const useStyles = makeStyles({
  container: {
    padding: "48px 64px",
  },
  mainTitle: {
    fontSize: 40,
    fontWeight: 500,
    marginBottom: 40,
  },
  separator: {
    border: 0,
    borderBottom: "1px solid #ececec",
    marginTop: 48,
  },
  searchSection: {
    display: "flex",
    flexDirection: "row",
  },
  searchBar: {
    display: "flex",
    marginRight: 15,
    position: "relative",
    flex: 1,
    "& .icon": {
      position: "absolute",
      top: 12,
      left: 15,
      color: "#5D6598",
    },
    "& .search-input": {
      borderRadius: 6,
      border: "1px solid #5D6598",
      outline: 0,
      padding: "14px 50px",
      flex: 1,
      color: "#5D6598",
    }
  },
  searchSort: {
    flexBasis: 200,
    position: "relative",
    "& .icon": {
      position: "absolute",
      top: 11,
      left: 12,
      zIndex: 2,
      color: "#5D6598",
    },
    "& select": {
      width: "100%",
      backgroundColor: "transparent",
      borderRadius: 6,
      border: "1px solid #5D6598",
      padding: "14px 20px 14px 40px",
      color: "#5D6598",
    }
  },
});

const ReportsContainer = () => {
  const { tempSearchQuery, searchQuery, updateSearchQuery } = useSearchQuery();
  const [sortDirection, setSortDirection] = useState("Newest");
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {/* ------ HEADER ------ */}
      <h2 className={classes.mainTitle}>Reports</h2>
      {/* ------ SEARCH ------ */}
      <div className={classes.searchSection}>
        <div className={classes.searchBar}>
          <SearchIcon className="icon"/>
          <input type="text" className='search-input' placeholder='Search' value={tempSearchQuery} onChange={e => updateSearchQuery(e.target.value)}/>
        </div>
        <div className={classes.searchSort}>
          <FilterListIcon className='icon'/>
          <select value={sortDirection} onChange={e => setSortDirection(e.target.value)}>
            <option>Newest</option>
            <option>Oldest</option>
          </select>
        </div>
      </div>
      <hr className={classes.separator}/>
      {/* ------ ACTIVE REPORTS ------ */}
      <ActiveReports
        searchQuery={searchQuery}
        sortDirection={sortDirection}
      />
      {/* ------ SAVED REPORTS ------ */}
      <SavedReports
        searchQuery={searchQuery}
        sortDirection={sortDirection}
      />
    </div>
  )
};

export default ReportsContainer;
