import { makeStyles } from "@mui/styles";
import Button from "components/Button";
import React from "react";
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';

const useStyles = makeStyles({
    container: {
        left: "50%",
        transform: "translateX(-50%)",
        position: "fixed",
        display: "flex",
        alignItems: "center",
        zIndex: 90,
        bottom: 40,
        borderRadius: 12,
        boxShadow: "0px 6px 27px rgba(0, 0, 0, 0.09)",
        backgroundColor: "#fff",
        border: "1px solid #ECEDF3",
        outline: 0,
        padding: "16px 32px",
        "@media print": {
            display: "none",
        },
    },
});

const ReportActions = () => {
    const classes = useStyles();
    const exportReport = () => {
        const currentTitle = document.title;
        const doNotPrintElements = document.querySelectorAll('.do-not-print');
        const reportHeader = document.getElementById("report-header");
        const reportBody = document.getElementById("report-body");
        const searchesContainer = document.getElementById("report-searches-container");
        const searchBlocks = document.querySelectorAll("[id*=search-]");

        // set the title to be shown on the pdf
        document.title = `ScARA Report: ${window.__report_title}`;
        
        // insert hr before each search block
        searchBlocks.forEach((block)=>{
            const hr = document.createElement('hr');
            hr.style.marginTop = '24px';
            block.before(hr);
        })

        // removing extra spaces to take the whole page
        reportHeader.style.padding = '0';
        reportBody.style.width = '100%';
        searchesContainer.style.marginTop = '24px';
        searchesContainer.childNodes.forEach(child => {
            child.style.padding = '0';
        });

        // hide unwanted elements
        doNotPrintElements.forEach(element => {
            element.style.display = 'none';
        });
        
        // hide empty comments
        document.querySelectorAll('.comment-box > div.ql-editor.ql-blank').forEach(element => {
            element.parentElement.parentElement.parentElement.style.display = 'none';
        });

        window.print();

        // reset the original title
        document.title = currentTitle;
        
        // remove inserted hrs
        document.querySelectorAll('#report-searches-container hr').forEach((hr)=>{
            hr.remove();
        })

        // reset original spaces
        reportHeader.style.padding = '';
        reportBody.style.width = '';
        searchesContainer.style.marginTop = '';
        searchesContainer.childNodes.forEach(child => {
            child.style.padding = '';
        });

        // re-show hidden elements
        doNotPrintElements.forEach(element => {
            element.style.display = '';
        });

        // re-show hidden empty comments
        document.querySelectorAll('.comment-box > div.ql-editor.ql-blank').forEach(element => {
            element.parentElement.parentElement.parentElement.style.display = '';
        });
    };

    return (
        <div className={classes.container}>
            <Button text={<CloudDownloadOutlinedIcon style={{ fontSize: 18 }} />} primary onClick={() => exportReport()} style={{ marginRight: 10 }} />
            <Button text="Save" primary onClick={() => { document.body.focus() }} />
        </div>
    );
};

export default ReportActions;

