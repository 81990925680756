import React, { useContext, useState } from 'react'
import { NewReport, ReportsList } from 'components/Reports';
import Popup from 'components/Popups';
import useRequest from 'hooks/useRequest';
import { reportService } from 'services';
import AppContext from 'AppContext';


const ActiveReports = ({ searchQuery, sortDirection }) => {
  const appContext = useContext(AppContext);
  const [page, setPage] = useState(0);

  const { loading, error, data, cachedData, refetch, setCachedData, setData, } = useRequest(() => {
    return reportService.getActiveReports(page, 100, searchQuery, sortDirection);
  }, [page, searchQuery, sortDirection]);

  const addReport = () => {
    Popup.firePopup(<NewReport onReportAdded={report => {
      if(report.active) {
        refetch();
        appContext.setCurrentReport(report);
      }
    }}/>);
  };

  return (
    <ReportsList
      // list details
      title="Active reports"
      emptyText="No active reports"
      addNewReport={addReport}
      // pagination
      page={page}
      size={6}
      setPage={setPage}
      // result
      loading={loading}
      error={error}
      totalReports={(cachedData && cachedData.count) || 0}
      reports={data ? data.reports : []}
      onDelete={(id) => reportService.deleteReport(id).then(()=>{
        setCachedData(data=>({...data, count:data.count-1, reports:data.reports.filter(r=>r.id!==id)}))
        setData(data=>({...data, count:data.count-1, reports:data.reports.filter(r=>r.id!==id)}))
        appContext.setCurrentReport(null);
      })}

    />
  );
}

export default ActiveReports;
