import dayjs from "dayjs";

export const dateDiffInDays = (a, b) => {
    const MS_PER_DAY = 1000 * 60 * 60 * 24;
    a=new Date(a)
    b=new Date(b)
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / MS_PER_DAY);
  }

export const formatToGoogleDate = (date)=>date?dayjs(date).format('YYYYMMDD'):""
export const formatToFDADate = (date)=>date?dayjs(date).format('MM/DD/YYYY'):""
export const formatToDate = (date, format)=>date?dayjs(date).format(format):""
