import { useMemo, useState } from 'react';
import { makeStyles } from '@mui/styles';
import DateSelector from 'components/DateSelector';
import UpArrowIcon from './icons/upArrow.svg';
import DownArrowIcon from './icons/downArrow.svg';
import AlertIcon from './icons/alert.svg';
import InfoIcon from './icons/info-icon.png';
import AppConfig from 'config';
import { CircularProgress } from '@mui/material';

const useStyles = makeStyles({
  container: {
    position: 'sticky',
    top: '72px',
    paddingInline: "18% 9%",
    zIndex: '1',
    "& h4": {
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "14px",
      lineHeight: "18px",
      color: "#5D6598",
    },
    "& ul": {
      padding: 0,
      "& li": {
        // display:'block',
        display: 'flex',
        justifyContent: "space-between",
        alignItems: 'center',
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "18px",
        color: "#30354F",
        marginBottom: 12,
        "& label": {
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          margin: 0,
          padding: 0,
          // "& span":{
          //   whiteSpace: 'nowrap',
          //   overflow: 'hidden',
          //   textOverflow: 'ellipsis'
          // }
        },
        "& span": {
          marginLeft: "12px",
        }
      },
    },
    "& input[type=checkbox]": {
      height: 16,
      width: 16,
      minWidth: 16,
      padding: "0",
      margin: "0",
    },
  },
  title: {
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "23px",
    color: "#30354F",
  },
  totalNumber: {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '18px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'right',
    marginLeft: 'auto',
    marginRight: '18px',
  },
  subFilters: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #ECEDF3',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '6px',
    textTransform: 'capitalize',
    padding: '16px 16px 0 16px',
    marginBlock: "-4px 8px",
  },
  filtersProvider: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '18px',
    color: '#30354F',
    background: '#FFFFFF',
    border: '1px solid #A6ABC9',
    borderRadius: '6px',
    padding: '12px 16px',
    marginBottom: 12,
    "& img": {
      "&[alt='flag']": {
        height: 16,
        width: 16,
        marginInline: "12px",
      },
      "&[alt='selector arrow']": {
        position: 'absolute',
        top: '50%',
        right: 16,
        transform: 'translateY(-50%)',
        width: 12,
        height: 12,
        marginLeft: '12px',
        cursor: 'pointer',
      }
    },
  },
  filtersTitle: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  infoIconContainer: {
    position: 'relative',
    display: 'inline-block',
    '& img': {
      width: 18,
      cursor: 'pointer',
      marginTop: 3,
      marginRight: 20,
    },
    '&>div': {
      visibility: 'hidden',
      width: 260,
      backgroundColor: 'black',
      color: '#fff',
      textAlign: 'center',
      borderRadius: 6,
      padding: 15,

      /* Position the tooltip */
      position: 'absolute',
      bottom: '100%',
      left: '50%',
      marginLeft: -60,
      zIndex: 1,
    },
    '&:hover > div': {
      visibility: 'visible',
    },
    '& ul': {
      listStyle: 'circle',
      textAlign: 'left',
    },
    '& ul li': {
      color: '#fff',
      display: 'list-item',
      marginLeft: 15,
    }
  }
});

const groupSourcesByProvider = (sources) => {
  const groupedSources = sources.reduce((acc, source) => {
    const provider = source.provider;
    if (!acc[provider]) acc[provider] = []
    acc[provider].push(source);
    return acc
  }, {})

  return Object.entries(groupedSources)
}

const ResultCount = ({ count, isDown = false, isLoading }) => {
  const classes = useStyles();

  if(isLoading) return(<span className={classes.totalNumber}><CircularProgress color="secondary" style={{width:18, height:18}} /></span>)

  if (!Number.isInteger(count)) return null;

  return (
    <span
      className={classes.totalNumber}
      style={{ color: isDown ? "#FF0000" : "#878787" }}
    >
      {count === -1 ? (
        <img src={AlertIcon} alt="alert" />
      ) : count >= 1000 ? (
        "999+"
      ) : (
        count
      )}
    </span>
  );
}

const Filters = ({ selectedFilters, setSelectedFilters, sources, totalResults, setValue, setIsSwitzerlandSelected, dispatchPagination, sourcesStatus}) => {
  const classes = useStyles();
  const groupedSources = groupSourcesByProvider(sources);
  const handleOnFilterSelect = (filterName) => {
    let isSwitzerlandSelected = false;
    if (selectedFilters.includes(filterName)) {
      setSelectedFilters(sf => sf.filter(f => f !== filterName))
    } else {
      isSwitzerlandSelected = true;
      setSelectedFilters(sf => [...sf, filterName])
      dispatchPagination({ type: 'init' });
    }
    if (filterName === "switzerland_region_search") setIsSwitzerlandSelected(isSwitzerlandSelected)
  }
  return (
    <div style={{ width: "25%", paddingBlock: '64px 0' }}>
      <div className={classes.container}>
        <h3 className={classes.title}>Filters</h3>
        <h4>DATE RANGE</h4>
        <DateSelector setDate={setValue} />
        <h4>DATABASES</h4>
        <ul>
          {groupedSources.map(([provider, subSources]) => {
            return <FiltersProvider
              key={provider}
              provider={provider}
              sources={subSources}
              counts={totalResults}
              handleOnFilterSelect={handleOnFilterSelect}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
              setIsSwitzerlandSelected={setIsSwitzerlandSelected}
              dispatchPagination={dispatchPagination}
              sourcesStatus={sourcesStatus}
            />
          })}
        </ul>
      </div>
    </div>
  );
}

export default Filters;

const FiltersProvider = ({ provider, sources, counts, handleOnFilterSelect, selectedFilters, setSelectedFilters, setIsSwitzerlandSelected, dispatchPagination, sourcesStatus }) => {
  const [areSubFiltersExpanded, setAreSubFiltersExpanded] = useState(false)
  const classes = useStyles();
  const imgPath = require(`./icons/flags/${sources[0].countryCode}.svg`);
  const providerCount = useMemo(() => {
    return sources.filter(({ label }) => counts[label] >= 0)
      .reduce((acc, src) => {
        acc += counts[src.label];
        return acc;
      }, 0);
  }, [sources, counts])


  const selectedSources = sources.filter(({ label }) => selectedFilters.includes(label)).map(({ name }) => name);
  const title = sources.length <= 1 ?
    sources[0].name : selectedSources.length > 0 ?
      selectedSources.length > 2 ?
        `${selectedSources[0]} +${selectedSources.length - 1} more` : selectedSources.join(',')
      : provider;

  const isCategorySelected = sources.reduce((acc, src) => {
    return acc && selectedFilters.includes(src.label);
  }, selectedFilters.includes(sources[0].label))

  const selectCategory = () => {
    let isNewCategorySelected = false;
    sources.forEach(({ label }) => {
      let isSwitzerlandSelected = false;
      if (!selectedFilters.includes(label)) {
        isSwitzerlandSelected = true;
        isNewCategorySelected = true;
        setSelectedFilters(sf => [...sf, label])
      } else if (isCategorySelected) {
        setSelectedFilters(sf => sf.filter(f => f !== label))
      }
      if (label === "switzerland_region_search") setIsSwitzerlandSelected(isSwitzerlandSelected)
    })
    if(isNewCategorySelected) dispatchPagination({ type: 'init' });
  }

  const toggleExpansion = (e) => {
    e.preventDefault();
    setAreSubFiltersExpanded(e => !e)
  }

  let tooltips = null;
  const limitations = AppConfig.limitations;
  sources.forEach(s => {
    if(limitations.hasOwnProperty(s.source)) {
      tooltips = limitations[s.source];
    }
  });


  const isSubSource = sources.some(src => counts[src.label] === -1);
  const isSourceLoading = sources.reduce((acc, src)=>{
      acc = acc || sourcesStatus[src.source]==="loading"
      return acc
    }, false)
  
  return <>
    <span className={classes.filtersProvider}>
      <input type="checkbox" onChange={selectCategory} checked={isCategorySelected} />
      <img alt="flag" src={imgPath} />
      <span className={classes.filtersTitle} title={title}>{title}</span>
      <ResultCount count={providerCount} isDown={isSubSource} isLoading={isSourceLoading}/>
      {(tooltips && tooltips.length > 0) && (
        <div className={classes.infoIconContainer}>
          <img src={InfoIcon} alt="Info" />
          <div className={classes.tooltipText}>
            <ul>
              {tooltips.map((t, i) => (
                <li key={`li-${sources[0].source}-${i}`}>
                  {t}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
      {sources.length > 1 && <img src={areSubFiltersExpanded ? UpArrowIcon : DownArrowIcon} alt='selector arrow' onClick={toggleExpansion} />}
    </span>
    {areSubFiltersExpanded && <SubFilters sources={sources} handleOnFilterSelect={handleOnFilterSelect} selectedFilters={selectedFilters} counts={counts} sourcesStatus={sourcesStatus}/>}
  </>
}

const SubFilters = ({ sources, handleOnFilterSelect, selectedFilters, counts, sourcesStatus }) => {
  const classes = useStyles();

  if (sources.length <= 1) return null

  return <div className={classes.subFilters}>{sources.map(({ label, name, source }) => {
    return (
      <li key={label}>
        <label>
          <input type="checkbox" onChange={() => handleOnFilterSelect(label)} checked={selectedFilters.includes(label)} />
          <span>{name}</span>
        </label>
        <ResultCount count={counts[label]} isLoading={sourcesStatus[source]==="loading"}/>
      </li>
    );
  })}
  </div>
}
