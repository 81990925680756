import React from "react";
import { makeStyles } from "@mui/styles";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#fff",
    padding: "12px 24px",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.08)",
    borderRadius: 48,
  },
  title: {
    fontSize: 14,
    fontWeight: 700,
    color: "#30354F",
    marginRight: 16,
  },
  links: {
    display: "flex",
    alignItems: "center",
    fontSize: 12,
    color: "#5D6598",
    fontWeight: 400,
  },
  linkIcon: {
    height: 14,
    width: 14,
    marginRight: 8,
  },
  gotoIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#CE2C3B",
    backgroundColor: "#FCEEF0",
    height: 24,
    width: 24,
    marginLeft: 30,
    borderRadius: 8,
    cursor: "pointer",
  },
});

const ReportLinkSmall = ({ reportId, title, links }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <span className={classes.title}>{title}</span>
      <Link to={`/reports/${reportId}`} className={classes.gotoIcon}>
        <ArrowForwardIcon style={{fontSize: 16}}/>
      </Link>
    </div>
  );
};

export default ReportLinkSmall;
