// export const fdaResultsAdapter= (data=[])=>{
//   return data?.map(d=>{
//     switch(d.source){
//       case "maude":
//         return {
//         ...d,
//         title:`New report for the manufacturer: ${d.manufacturer}`,
//         description:`FDA MAUDE News: The brand ${d.brand} has a received a new report at ${d.date} for a device manufactured by ${d.manufacturer}. Follow the link for more details.`
//       }
//       case "pma":
//       case "510k":
//         return {
//           ...d,
//           title:`${d.device}  by the manufacturer ${d.manufacturer}`,
//           description: `FDA ${d.source.toUpperCase()} News: The device ${d.device}, submitted by the manufacturer ${d.manufacturer} and identified by ${d.id} has received a decision at the following date ${d.date}. Follow the link for more details.`,
//         }
//       default:
//         throw new Error(`FDA source: ${d.source} should be added to the fda adapter`);
//       }


//   })
// }
import AppConfig from 'config';
import { formatToFDADate } from "utils";
const sources = AppConfig.sources;

const getDefaultResult= source =>sources.filter(s=>s.source===source).reduce((acc,s)=>{
  if(!acc.total) acc.total={}
  acc[s.label]=[];
  acc.total[s.label]=0;
  return acc;
},{})

export const saluteResultsAdapter = (data=[], page) => {

  const results = data?.data?.map(d => {

    return {
      title: `${d.DENOMINAZIONE_COMMERCIALE} by manufacturer ${d.FABBRICANTE_ASSEMBLATORE}`,
      description: `${d.DESCRIZIONE_CND}, publication date: ${formatToFDADate(d.DATA_PRIMA_PUBBLICAZIONE)}, initial validation date: ${formatToFDADate(d.INIZIO_VALIDITA)}`,
      link: `https://www.salute.gov.it/interrogazioneDispositivi/RicercaDispositiviServlet?action=ACTION_MASCHERA&salute_id=${d.id}`,
      date: formatToFDADate(d.DATA_PRIMA_PUBBLICAZIONE),
      actions: ["save", "copy"],
      type: 'salute',
      salute: {
        devices: [d.DENOMINAZIONE_COMMERCIALE],
        fabricant: d.FABBRICANTE_ASSEMBLATORE,
        description: `${d.CLASSIFICAZIONE_CND} - ${d.DESCRIZIONE_CND}`,
        publicationDate: formatToFDADate(d.DATA_PRIMA_PUBBLICAZIONE),
        intialValidationDate: formatToFDADate(d.INIZIO_VALIDITA)
      }
    }
  });


  return {
    salute: results,
    total: {
      salute: data?.count
    }
  };
}


export const saluteAvvisiResultsAdapter = (data=[], page) => {

  const results = data?.results?.map(d => {
    return {
      title: d.title,
      description: `${d.description}, Device type: ${d.type}`,
      link: d.link,
      date: d.date
    }
  });

  return {
    salute_avvisi: results,
    total: {
      salute_avvisi: data?.total_count
    }
  };
}

export const fdaResultsAdapter= (data=[], page)=>{
  const initValue = getDefaultResult('fda_scrapper')


  const results = data?.result?.reduce((acc, d)=>{
    if(!acc?.[d.source]) acc[d.source]=[]
    switch(d.source){
      case "maude":
        acc[d.source].push({
        ...d,
        title:`New report for the manufacturer: ${d.manufacturer}`,
        description:`FDA MAUDE News: The brand ${d.device} has received a new report at ${d.date} for a device manufactured by ${d.manufacturer}. Follow the link for more details.`
      })
      return acc;
      case "recalls":
        acc[d.source].push({
          ...d,
          title:`${d.device}  by the recalling firm ${d.manufacturer}`,
          description: `FDA ${d.source.toUpperCase()} News: The device ${d.device}, by recalling firm ${d.manufacturer} has a recall class of ${d.id}, posted at the following date ${d.date}. Follow the link for more details.`,
        })
        return acc;
      case "pma":
      case "510k":
        acc[d.source].push({
          ...d,
          title:`${d.device}  by the manufacturer ${d.manufacturer}`,
          description: `FDA ${d.source.toUpperCase()} News: The device ${d.device}, submitted by the manufacturer ${d.manufacturer} and identified by ${d.id} has received a decision at the following date ${d.date}. Follow the link for more details.`,
        })
        return acc;
      case "warning_letters":
        acc[d.source].push({
          ...d,
          title:`letter for the manufacturer ${d.manufacturer}`,
          description: `Subject is ${d.subject}. Issued at ${d.issue_date} by ${d.issuing_office} and posted at ${d.date}. Follow the link for more details.`,
        })
        return acc;
      default:
        throw new Error(`FDA source: ${d.source} should be added to the fda adapter`);
      }
  },initValue) || initValue

  results.total =Object.assign(initValue.total,{
    '510k': data.five10k_count,
    'pma': data.pma_count,
    'maude': data.maude_count,
    'recalls': data.recalls_count,
    'warning_letters': data.warning_letters_count,
  })
  results.totalResults = data.five10k_count + data.pma_count + data.maude_count;
  results.totalPages= Math.ceil(results.totalResults/page.size) || 0;
  return results
}

export const googleSearchResultsAdapter= (results=[])=>{
  return results?.map(d=>({
    ...d,
    description:(d?.pagemap?.metatags?.[0]?.['og:description']) ? d.pagemap.metatags[0]['og:description'] : (d.snippet || ''),
  }))
}
// export const googleSearchResultsAdapter = (data = []) => {
//   const initValue = getDefaultResult('google')

//   return data?.reduce((acc, d) => {
//     d?.labels?.forEach(label => {
//       if (!acc[label.name]) acc[label.name] = [];
//       if (!acc.total[label.name]) acc.total[label.name] = 0;
//       acc[label.name].push({
//         ...d,
//         description: (d?.pagemap?.metatags?.[0]?.['og:description']) ? d.pagemap.metatags[0]['og:description'] : (d.snippet || ''),
//       })
//       ++acc.total[label.name];
//     });

//     return acc;
//   },initValue)
// }

export const zefixResultsAdapter= (results, legalFormData, registerOfficeData, page)=>{
  const start = (page.number-1)*page.size;
  const end = page.number*page.size;
  const data =  results?.slice(start, end)?.map(({name, ehraid, uidFormatted, legalSeat, legalFormId, registerOfficeId, uid})=>{
    const legalForm = legalFormData?.find(lf=>lf.id===legalFormId)?.kurzform?.en;
    const registerOffice = registerOfficeData?.find(ro=>ro.id===registerOfficeId)?.canton;
    return {
      name,
      uid,
      ehraid,
      uidFormatted,
      legalSeat,
      legalForm,
      registerOffice
    }
  })
  if(data){
    data.totalResults = results?.totalResults?? results?.length;
    data.totalPages =results?.totalPages?? (Math.ceil(data.totalResults/page.size) || 0)
  }

  return data;
}

export const eudamedResultsAdapter = (results, label)=>{
  let data = results.content
  switch (label) {
    case "eudamed_economic_operators":
      data = data.map(res=>{
         const manufacturer = res.name;
         const srn = res.srn;

         return {
           ...res,
           title:`Report for the manufacturer: ${manufacturer}`,
           description: `Report for ${manufacturer} identified by ${srn} registered in ${res.cityName}, ${res.countryName}. Follow the link for more details.`,
           link: `https://ec.europa.eu/tools/eudamed/#/screen/search-eo/${res.uuid}`
         }
       })
      break;
    case "eudamed_devices_spps":
      data = data.map(res=>{
        const manufacturer = res.manufacturerName;
        const srn = res.manufacturerSrn;
        const device = res.tradeName;
        const basicUdi = res.basicUdi;

        return {
          ...res,
          title:`Report for the manufacturer ${manufacturer}`,
          description: `Latest report ${device?'about the device '+device:basicUdi?'about the device with the basic UDI '+basicUdi:''} for the manufacturer ${manufacturer} identified by ${srn}. Follow the link for more details.`,
          link: `https://ec.europa.eu/tools/eudamed/#/screen/search-device/${res.uuid}`
        }
      })
      break;
    case "eudamed_certificates":
      data = data.map(res=>{
        const manufacturer = res.actorName;
        const srn = res.actorSrn;
        const certificate = res.certificateNumber;
        const status = res.certificateStatus && res.certificateStatus.code?.slice(res.certificateStatus.code?.lastIndexOf('.')+1)
        const issueDate = res.issueDate;
        const expiryDate = res.expiryDate;


        return {
          ...res,
          title:`${certificate} for the manufacturer ${manufacturer}`,
          description: `Certificate ${certificate} with status ${status} for ${manufacturer} identified by ${srn}, issued at ${issueDate} with expiry date at ${expiryDate}. Follow the link for more details.`,
          link: `https://ec.europa.eu/tools/eudamed/#/screen/certificates/${res.uuid}`
        }
      })
      break;
    default:
      throw new Error(`Eudamed label: ${label} should be added to the eudamed adapter.`);
    }

  data.total = {
    [label]: results.totalElements
  }
  //console.log(data)
  return data;
}

export const swissmedicResultsAdapter = (data=[], label)=>{

  const results = data?.content?.map(r => ({
    title:`Report for fabricant: ${r.hersteller}`,
    description: `Report for  identified by registered in Follow the link for more details.`,
    link: (r.documents && Array.isArray(r.documents) && r.documents.length > 0)
      ? `https://fsca.swissmedic.ch/mep/api/publications/${r.swissmedicRef}/documents/0` : '#',
    date: r.publikationsDatum,
    type: 'swissmedic',
    mime: 'text/pdf',
    swissmedic: {
      devices: r.devices,
      documents: r.documents,
      reference: r.swissmedicRef,
      fabricant: r.hersteller
    }
  }));


  results.totalPages = data?.totalPages;
  results.total = {
    swissmedic_fscas: data?.totalElements
  }
  return results;
}

export const opendataSwissResultsAdapter = (data=[], label)=>{

  const results = data?.results?.map(r => {
    return {
      title: r.Name || 'N/A',
      chrn: r.CHRN || 'N/A',
      name: r.Name || 'N/A',
      pocode: r.POCode || 'N/A',
      city: r.City || 'N/A',
      email: r.EMail || 'N/A',
      website: r.Website,
    }
  });


  results.totalPages = data?.totalPages;
  results.total = {
    opendata_swiss: data?.totalElements
  }
  return results;
}

export const pubmedResultsAdapter = (data=[], label)=>{

  let results = data.results.map((res)=>{
    return {
      ...res,
      link: "https://pubmed.ncbi.nlm.nih.gov/"+res.pmid,
      description: res.abstract?.[0],
    }
  })
  results.total = {
    [label]: data.count
  }
  return results;
}
