import { useEffect, useState } from 'react';

export default function useRequest(promiseFunc, args) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(undefined);
    const [data, setData] = useState(undefined);
    const [cachedData, setCachedData] = useState(undefined);
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        setLoading(true);
        setData(undefined);
        setError(undefined);

        promiseFunc && promiseFunc().then(
            (data) => {
                setCachedData(data.data);
                setData(data.data);
                setLoading(false);
            }).catch((error) => {
                setError(error);
                setLoading(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, args ? [...args, refresh] : [refresh]);

    return { 
        loading,
        error,
        data,
        cachedData, 
        refetch: () => setRefresh(!refresh),
        setCachedData,
        setData,
    };
};