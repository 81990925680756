import { makeStyles } from '@mui/styles';
import { CircularProgress } from "@mui/material";
import { Fragment, useContext, useEffect, useState } from 'react';
import SearchResultsItem from 'components/SearchResultsItem';
import ZefixResultsItem from 'components/ZefixResultsItem';
import OpendataSwissResultsItem from 'components/OpendataSwissResultsItem';
import SizeSelector from 'components/SizeSelector';
import FilterBadge from 'components/FilterBadge';
import Pagination from 'components/Pagination';
import Error from 'components/Error';
import earthIcon from './icons/earth.svg';
import PreviewModal from 'components/PreviewModal';
import { saveLinkToActiveReport, saveOpendataSwissToActiveReport } from 'services/report.service';
import { toast } from 'react-toastify';
import AppContext from "AppContext";
import saveIcon from './icons/save.svg';

const useStyles = makeStyles({
  container: {
    flexGrow: '1',
    width: 'calc(75% - 64px)',
    padding: '64px 64px 64px 32px',
    borderLeft: "1px solid #E3E3E3",
  },
  title: {
    marginBlock: "64px 40px",
    height: "1px",
    background: "#E3E3E3",
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "center",
    "& div": {
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "14px",
      color: "#000000",
      backgroundColor: "#FFFFFF",
      padding: "0 17px"
    }
  },
  header: {
    marginBottom: '-24px',
  },
  filterBadges: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '16px',
    marginBottom: 24,
  },
  totalResults: {
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '23px',
    display: 'flex',
    alignItems: 'center',
    color: '#30354F'
  },
  pageNumber: {
    fontSize: '14px',
    color: '#30354F',
    marginInline: '12px auto',
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  },
  actionBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 32,
    height: 32,
    cursor: 'pointer',
    background: '#F1F1F1',
    borderRadius: "6px",
    border: 'none',
  },
})

const SearchResults = ({ totalPages, results, totalResults, selectedFilters, setSelectedFilters, sources, page, dispatchPagination, isOneSourceFinished, currentSearchId }) => {

  const classes = useStyles();
  const [showModal, setShowModal] = useState(false);
  const [previewInfos, setPreviewInfos] = useState({link:"", source:""});
  const [filteredResults, setFilteredResults] = useState([]);
  const { currentReport } = useContext(AppContext);

  const getNameFromLabel = (label) => sources.find(f => f.label === label)?.name;
  const total = Object.values(totalResults).reduce((acc, total) => {
    return acc + Math.max(total || 0, 0)
  }, 0)

  const handlePreviousPage = () => {
    dispatchPagination({ type: "previous" })
  }

  const handleNextPage = () => {
    dispatchPagination({ type: "next" })
  }

  const handlePageNumberClick = (number) => {
    dispatchPagination({ type: 'number', number })
  }

  const saveLinks = (source, links) => {
    if(source.toLowerCase() === "opendata_swiss"){
      const sources = links.map(
        ({ chrn, name, pocode, city, email, website }) => {
          return {
            title: name,
            chrn,
            name,
            pocode,
            city,
            email,
            website,
            url: `#${chrn}|${name}`,
            source,
            searchId: currentSearchId,
          };
        }
      );
      saveOpendataSwissToActiveReport(currentReport.id, sources).then((data) => {
        if (data.data.success) {
          toast.success("Company saved into active report!", { autoClose: 2000 });
        }
      }).catch(() => {
        toast.error("Couldn't save the report.");
      })
    }else{
      const sources = links.map(({title, description, link, date})=>({
        title,
        description,
        date: new Date(date||null).toISOString(),
        link,
        source,
        comment: '',
        non_editable_html: '',
        searchId: currentSearchId,
      }))
      saveLinkToActiveReport(currentReport.id, sources).then((data) => {
        if (data.data.success) {
          toast.success("Company saved into active report!", { autoClose: 2000 });
        }
      }).catch(() => {
        toast.error("Couldn't save the report.");
      })
    }
  }

  useEffect(() => {
    const data = Object.entries(results).filter(([key]) => selectedFilters.includes(key));

    //sorting the results
    sources.forEach(({ label }) => {
      const result = results?.[label];
      delete results[label];
      results[label] = result;
    });
    setFilteredResults(data);
  }, [sources, results, selectedFilters])

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.filterBadges}>
          {selectedFilters?.map((selectedFilter) => {
            return <FilterBadge key={selectedFilter} label={selectedFilter} name={getNameFromLabel(selectedFilter)} setSelectedFilters={setSelectedFilters} />
          })}
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span className={classes.totalResults}>{total} results</span>
          {page.number > 1 && <span className={classes.pageNumber}>Page {page.number}</span>}
          <SizeSelector size={page.size} setSize={(size) => dispatchPagination({ type: "size", size })} />
        </div>
      </div>
      <div style={{ height: 'calc(100% - 64px - 64px)' }}>
        {!isOneSourceFinished ? (
          <div className={classes.loader}>
            <CircularProgress color="secondary" />
          </div>
        ) : (
          filteredResults?.map(([title, result]) => {
            return (
              <Fragment key={title}>
                {(result?.length > 0 || totalResults[title] === -1) && (
                  <span key={title}>
                    <div className={classes.title}>
                      <div>{getNameFromLabel(title)}</div>
                     {title.toLowerCase() !== "zefix" && <button className={classes.actionBtn} onClick={()=>saveLinks(title, result)}><img src={saveIcon} alt="save all"/></button>}
                    </div>
                    {totalResults[title] === -1 ? (
                      <Error
                        title="Database error"
                        description="It seems that the database isn't available at the moment. Please try again later."
                      />
                    ) : title.toLowerCase() === "zefix" ||
                      title.toLowerCase() === "opendata_swiss" ? (
                      title.toLowerCase() === "zefix" ? (
                        result?.map((r) =>{ 
                          const link = `https://www.zefix.ch/en/search/entity/list/firm/${r.ehraid}`;

                          return(
                          <ZefixResultsItem
                            key={r.ehraid}
                            link={link}
                            source={title}
                            currentSearchId={currentSearchId}
                            {...r}
                          >
                             <button
                              className={classes.actionBtn}
                              onClick={(e) => {
                                setPreviewInfos({...r,link:"https://www.uid.admin.ch/Detail.aspx?uid_id="+r.uid, source:title, title:r.name, currentSearchId});
                                setShowModal(true)
                              }}
                            >
                              <img src={earthIcon} alt="website modal" />
                            </button>
                            </ZefixResultsItem>
                        )})
                      ) : (
                        result?.map((r) => (
                          <OpendataSwissResultsItem
                            key={r.ehraid}
                            source={title}
                            currentSearchId={currentSearchId}
                            {...r}
                          />
                        ))
                      )
                    ) : (
                      result?.map((r) => (
                        <>
                          <SearchResultsItem
                            key={r.link}
                            source={title}
                            currentSearchId={currentSearchId}
                            {...r}
                          >
                            <button
                              className={classes.actionBtn}
                              onClick={(e) => {
                                setPreviewInfos({...r, source:title, currentSearchId});
                                setShowModal(true)
                              }}
                            >
                              <img src={earthIcon} alt="website modal" />
                            </button>
                          </SearchResultsItem>
                        </>
                      ))
                      )}
                  </span>
                )}
              </Fragment>
            );
          })
          )}
      </div>
      <PreviewModal previewInfos={previewInfos} showModal={showModal} setShowModal={setShowModal}></PreviewModal>
      {totalPages > 1 && selectedFilters.length > 0 && <Pagination count={totalPages} pageNumber={page.number} {...{ handlePageNumberClick, handlePreviousPage, handleNextPage }} />}
      {/*  (results && Array.isArray(results)) && results?.length>0 ? (results?.map((r) =><SearchResultCard key={r.link} {...r} />)) : (
       <p style={{
         paddingLeft: 10
       }}><strong>No results found...</strong></p>
     )} */}
    </div>
  );
}



export default SearchResults;
