export default ({color="#4590F7"})=><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
<g clip-path="url(#clip0_5344:43078)">
<path d="M14.3336 4.6665H13.0003C12.8235 4.6665 12.6539 4.73674 12.5289 4.86177C12.4039 4.98679 12.3337 5.15636 12.3337 5.33317C12.3337 5.50998 12.4039 5.67955 12.5289 5.80458C12.6539 5.9296 12.8235 5.99984 13.0003 5.99984H14.3336C14.4221 5.99984 14.5068 6.03496 14.5694 6.09747C14.6319 6.15998 14.667 6.24477 14.667 6.33317V14.3332C14.667 14.4216 14.6319 14.5064 14.5694 14.5689C14.5068 14.6314 14.4221 14.6665 14.3336 14.6665H6.33366C6.24525 14.6665 6.16047 14.6314 6.09795 14.5689C6.03544 14.5064 6.00032 14.4216 6.00032 14.3332V12.9998C6.00032 12.823 5.93009 12.6535 5.80506 12.5284C5.68004 12.4034 5.51047 12.3332 5.33366 12.3332C5.15685 12.3332 4.98728 12.4034 4.86225 12.5284C4.73723 12.6535 4.66699 12.823 4.66699 12.9998V14.3332C4.66699 14.552 4.7101 14.7688 4.79386 14.971C4.87762 15.1732 5.00038 15.3569 5.15515 15.5117C5.30991 15.6665 5.49364 15.7892 5.69585 15.873C5.89806 15.9567 6.11479 15.9998 6.33366 15.9998H14.3336C14.5525 15.9998 14.7692 15.9567 14.9715 15.873C15.1737 15.7892 15.3574 15.6665 15.5122 15.5117C15.6669 15.3569 15.7897 15.1732 15.8734 14.971C15.9572 14.7688 16.0003 14.552 16.0003 14.3332V6.33317C16.0003 6.1143 15.9572 5.89758 15.8734 5.69537C15.7897 5.49316 15.6669 5.30942 15.5122 5.15466C15.3574 4.9999 15.1737 4.87713 14.9715 4.79337C14.7692 4.70961 14.5525 4.6665 14.3336 4.6665Z" fill={color}/>
<path d="M10.0003 11.3333C10.3539 11.3333 10.6931 11.1929 10.9431 10.9428C11.1932 10.6928 11.3337 10.3536 11.3337 10V1.33333C11.3337 0.979711 11.1932 0.640573 10.9431 0.390524C10.6931 0.140476 10.3539 0 10.0003 0L1.33366 0C0.980036 0 0.640897 0.140476 0.390849 0.390524C0.1408 0.640573 0.000324249 0.979711 0.000324249 1.33333L0.000324249 10C0.000324249 10.3536 0.1408 10.6928 0.390849 10.9428C0.640897 11.1929 0.980036 11.3333 1.33366 11.3333H10.0003ZM1.33366 1.66667C1.33366 1.57826 1.36878 1.49348 1.43129 1.43096C1.4938 1.36845 1.57859 1.33333 1.66699 1.33333H9.66699C9.7554 1.33333 9.84018 1.36845 9.90269 1.43096C9.96521 1.49348 10.0003 1.57826 10.0003 1.66667V9.66667C10.0003 9.75507 9.96521 9.83986 9.90269 9.90237C9.84018 9.96488 9.7554 10 9.66699 10H1.66699C1.57859 10 1.4938 9.96488 1.43129 9.90237C1.36878 9.83986 1.33366 9.75507 1.33366 9.66667V1.66667Z" fill={color}/>
</g>
<defs>
<clipPath id="clip0_5344:43078">
<rect width="16" height="16" fill="white"/>
</clipPath>
</defs>
</svg>