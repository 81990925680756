import React from "react";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";

const useStyles = makeStyles({
  button: {
    display: "flex",
    alignItems: "center",
    float: "right",
    backgroundColor: (props) => props.primary ? "#CE2C3B" : "#fff",
    fontSize: 14,
    fontWeight: 400,
    color: (props) => props.primary ? "#fff" : "#47486A",
    border: (props) => props.primary ? 0 : "1px solid #A6ABC9",
    padding: "8px 14px",
    borderRadius: "6px",
    cursor: "pointer",
    userSelect: "none",
    outline: 0,
  },
  icon: {
    fontSize: 16,
    marginRight: 6,
  },
});

const Button = ({ text, primary, Icon, className, ...props }) => {
  const classes = useStyles({
    primary,
  });

  return <button className={classNames(classes.button, className)} {...props}>{Icon && <Icon className={classes.icon} />} {text}</button>;
};

export default Button;
