import { makeStyles } from "@mui/styles";
import { reportService } from 'services';
import AppContext from "AppContext";
import { toast } from 'react-toastify';
import { copyToClipboard } from "components/CopyToClipboard";
import copyIcon from './icons/copy.svg';
import saveIcon from './icons/save.svg';
import { useContext } from "react";

const useStyles = makeStyles({
  container: {
    position: 'relative',
    background: "#FFFFFF",
    border: "1px solid #ECEDF3",
    boxSizing: "border-box",
    boxShadow: "0px 2px 6px rgba(59, 100, 241, 0.25)",
    borderRadius: "16px",
    height: "120px",
    width: "100%",
    padding: "24px 32px",
    marginBottom: "18px"
  },
  actions: {
    display:"flex"
  },
  title: {
    display: "block",
    textAlign: "start",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "18px",
    marginBottom: "12px",
    textDecoration: "none",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginRight:'auto',
  },
  infoItem: {
    background: "#FFFFFF",
    border: "1px solid #A6ABC9",
    boxSizing: "border-box",
    borderRadius: "6px",
    height: "40px",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    display: "flex",
    alignItems: "center",
    color: "#30354F",
    padding: "0 16px 0 16px",
    '@media(max-width: 1500px)' : {
      fontSize: "14px",
    },
  },
  infoItemsList: {
    display: "flex",
    gap: "16px"
  },
  actionBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 32,
    height: 32,
    cursor: 'pointer',
    background: '#F1F1F1',
    borderRadius: "6px",
    border: 'none',
    marginLeft: 12,
    marginBlock: 'auto',
    float: 'right',
  },
});
const ZefixResultsItem = ({ name, uid, link, uidFormatted, legalSeat, legalForm, registerOffice, source, currentSearchId, children }) => {
  const classes = useStyles();
  const { currentReport } = useContext(AppContext);

  return (
    <div className={classes.container}>
      <div className={classes.actions}>
      <a
        className={classes.title}
        href={link}
        title={name}
        target="_blank"
        rel="noreferrer"
      >
        {name}
      </a>
      {children}
      {currentReport && <button
        className={classes.actionBtn}
        onClick={(e) => {
          reportService
            .saveZefixToActiveReport(currentReport.id, {
              title: name,
              uid,
              link,
              uidFormatted,
              legalForm,
              legalSeat,
              registerOffice,
              source,
              searchId: currentSearchId,
            })
            .then((data) => {
              if (data.data.success) {
                toast.success("Company saved into active report!", {
                  autoClose: 2000,
                });
              }
            })
            .catch(() => {
              toast.error("Couldn't save the report.");
            });
          e.preventDefault();
        }}
      >
        <img src={saveIcon} alt="save" />
      </button>}
      <button
        className={classes.actionBtn}
        onClick={(e) => {
          e.preventDefault();
          copyToClipboard(
            {
              Title: name,
              Link: link,
              UID: uidFormatted,
              "Legal form": legalForm,
              Seat: legalSeat,
              "Canton/rc": registerOffice,
            },
            toast
          );
        }}
      >
        <img src={copyIcon} alt="copy" />
      </button>
      </div>
      <div className={classes.infoItemsList}>
        <span className={classes.infoItem}>UID : {uidFormatted}</span>
        <span className={classes.infoItem}>Legal form : {legalForm}</span>
        <span className={classes.infoItem}>Seat : {legalSeat}</span>
        <span className={classes.infoItem}>Canton/rc : {registerOffice}</span>
      </div>
    </div>
  );
};

export default ZefixResultsItem;
