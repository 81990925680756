import { Close } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const useStyles = makeStyles({
  closeIcon:{
    position: 'relative',
    top: '-10px',
    right: '-10px',
    border: 'none',
    cursor: 'pointer',
    marginLeft: 'auto',
    display: 'flex',
    width: '16px',
    height: '16px',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#0000'
  },
  title:{
    marginBlock: '8px 32px',
  },
  cancelBtn:{
    marginLeft:'auto',
    backgroundColor: '#FFF !important',
    color:'#000 !important',
    outline:'1px solid #000 !important',
    marginRight:12,
  },
  actions:{
    display:'flex',
    '& button':{
      color: '#FFF',
      border: 'none',
      cursor: 'pointer',
      fontSize: '16px',
      borderRadius: '6px',
      backgroundColor: '#CE2C3B',
      padding:'8px 12px',
      outline:'1px solid #CE2C3B',
    }
  },
})
// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

export default function DeletionAlert({modalIsOpen, setIsOpen, onDelete}) {
  const classes = useStyles();
  function closeModal() {
    setIsOpen(false);
  }

  return (
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Are you sure you want to delete this report?"
      > 
        <button className={classes.closeIcon} onClick={closeModal}><Close/></button>
        <h2 className={classes.title}>Are you sure you want to delete this report?</h2>
        <div className={classes.actions}>
          <button className={classes.cancelBtn} onClick={closeModal}>Cancel</button>
          <button onClick={onDelete}>Delete</button>
        </div>
      </Modal>
  );
}
