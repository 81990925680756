import { makeStyles } from "@mui/styles";
import { toast } from "react-toastify";
import Copy from "./icons/Copy";

const useStyles = makeStyles({
  copy: {
    cursor: "pointer",
    position: "absolute",
    right: "16px",
    top: "50%",
    transform: "translateY(-50%)",
  },
});

const CopyToClipboard = ({toCopy, className})=>{
    const classes = useStyles()
    const copy = () => {
      const text = Object.entries(toCopy).reduce((acc, [key, value]) => {
        acc += value ? `${key}: ${value}\n` : "";
        return acc;
      }, "");
      navigator.clipboard.writeText(text).then(
        function () {
          /* clipboard successfully set */
          toast.success("Copied!", { autoClose: 2000 });
        },
        function () {
          /* clipboard write failed */
          toast.error("Couldn't be copied.");
        }
      );
    };

    return <span onClick={copy} className={className || classes.copy}><Copy /></span>
}

export default CopyToClipboard;