import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { CircularProgress, Grid } from "@mui/material";
import ReportCard from "./ReportCard";
import Button from "components/Button";
import Pagination from "components/Pagination/reports/Pagination";
import DeletionAlert from "./DeletionAlert";

const useStyles = makeStyles({
  container: {
    marginTop: 48,
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    marginBottom: 40,
    color: "#131520",
  },
  count: {
    fontSize: 16,
    color: "#878787",
    marginLeft: 16,
  },
  empty: {
    backgroundColor: "#F4F5F5",
    padding: "22px 18px",
    borderRadius: 6,
    fontSize: 16,
    color: "#8E929A",
    fontWeight: 700,
  },
  addReportIcon: {
    fontSize: 16,
    marginRight: 6,
  },
  card: {
    margin: 10,
    boxShadow: "0px 2px 6px rgba(59, 100, 241, 0.25)",
    padding: "20px 24px",
    backgroundColor: "#fff",
    border: "1px solid #ECEDF3",
    borderRadius: 8,
  },
  errorMessage: {
    display: "flex",
    alignItems: "center",
    padding: "30px 0px",
    fontWeight: 700,
    fontSize: 12,
    color: "#CE2C3B",
  },
});

const ReportsList = ({title, emptyText, addNewReport, loading, error, reports, totalReports, page, setPage, size=10, onDelete}) => {
  const classes = useStyles();
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
  const [reportToDelete, setReportToDelete] = useState()

  const Reports = () => {
    // loading the results from api
    if(loading) return <CircularProgress style={{color: "#5D6598", width: 30, height: 30}}/>;
    // error encountered
    if(error) return <span className={classes.errorMessage}>{String(error)}</span>;
    // the result is empty
    if(!reports || reports.length === 0) return (
      <div className={classes.empty}>{emptyText || "No reports found"}</div>
    );

    // display the result list
    return (
      <React.Fragment>
        <Grid container>
          {reports.map((elem, key) =>
            <ReportCard onDelete={() => {
              setReportToDelete(elem.id)
              setIsDeleteAlertOpen(true)
            }} 
            report={elem}
            key={key}/>
          )}
        </Grid>
      </React.Fragment>
    );
  };

  return (
    <div className={classes.container}>
      <h4 className={classes.title}>
        {title} <span className={classes.count}>({totalReports || 0})</span>
        {addNewReport && <Button primary text="New report" Icon={AddCircleOutlineIcon} onClick={addNewReport}/>}
      </h4>
      <Reports/>
      {totalReports > size && <Pagination total={totalReports} page={page} setPage={setPage} size={size}/>}
      <DeletionAlert modalIsOpen={isDeleteAlertOpen} setIsOpen={setIsDeleteAlertOpen} onDelete={() => {
              onDelete(reportToDelete)
              setIsDeleteAlertOpen(false);
            }} />
    </div>
  );
};

export default ReportsList;
