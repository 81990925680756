import { useState } from 'react';

export default function useSearchQuery() {
    const [searchQuery, setSearchQuery] = useState("");
    const [tempSearchQuery, setTempSearchQuery] = useState("");
    const [queryTimeout, setQueryTimeout] = useState(0);

    const updateSearchQuery = (query) => {
        setTempSearchQuery(query);
        if (queryTimeout) clearTimeout(queryTimeout);
        setQueryTimeout(setTimeout(() => {
            setSearchQuery(query);
        }, 400));
    };

    return { tempSearchQuery, searchQuery, updateSearchQuery };
};