import { useEffect, useState } from "react";
import {
  useFDAScrapper,
  useGoogleSearch,
  useZefix,
  useOpendataSwiss,
  useEudamedEOScrapper,
  useEudamedDevicesSppsScrapper,
  useEudamedCertificatesScrapper,
  useSwissmedicScrapper,
  useSwitzerlandGoogleSearch,
  usePubmedScrapper,
  useSaluteScrapper,
  useSaluteAvvisiScrapper
} from "./api";

export const useSearch = (formValues, page, selectedFilters) => {
  const [results, setResults] = useState({});
  const [totalResults, setTotalResults] = useState({});
  const [submitted, setSubmitted] = useState(false);
  // const { data: FDAResults = {}, status: FDAScrapperStatus } = { data: {}, status: 'idle' };
  const shouldBeFetched = (sourceLabel)=>{
    if(page.number===1) return submitted
    if(!Array.isArray(sourceLabel)) sourceLabel = [sourceLabel]
    const isSourceSelected = selectedFilters.some(sf=>sourceLabel.includes(sf));
    return submitted && isSourceSelected;
  }
  const { data: FDAResults = {}, status: FDAScrapperStatus } = useFDAScrapper(formValues, page, shouldBeFetched);
  const { data: zefixResults, status: zefixStatus } = useZefix(formValues.applicant, page, shouldBeFetched);
  const { data: googleResults = {}, status: googleStatus } = useGoogleSearch(formValues, page, shouldBeFetched);
  const { data: switzerlandGoogleResults = {}, status: switzerlandGoogleStatus, refetch: fetchSwitzerlandResults } = useSwitzerlandGoogleSearch(formValues, page, false);
  const { data: eudamedEOResults = {}, status: eudamedEOStatus } = useEudamedEOScrapper(formValues, page, shouldBeFetched);
  const { data: eudamedDevicesSppsResults = {}, status: eudamedDevicesSppsStatus } = useEudamedDevicesSppsScrapper(formValues, page, shouldBeFetched);
  const { data: eudamedCertificatesResults = {}, status: eudamedCertificatesStatus } = useEudamedCertificatesScrapper(formValues, page, shouldBeFetched);
  const { data: pubmedResults = {}, status: pubmedStatus } = usePubmedScrapper(formValues, page, shouldBeFetched);
  const { data: swissmedicResults = {}, status: swissmedicStatus } = useSwissmedicScrapper(formValues, page, shouldBeFetched);
  const {data:SaluteResults={}, status:SaluteScrapperStatus} = useSaluteScrapper(formValues, page, shouldBeFetched);
  const {data:SaluteAvvisiResults={}, status:SaluteAvvisiScrapperStatus} = useSaluteAvvisiScrapper(formValues, page, shouldBeFetched);
  const { data: opendataSwissResults, status: opendataSwissStatus } = useOpendataSwiss(formValues, page, shouldBeFetched);

  const areResultsLoading = [zefixStatus, FDAScrapperStatus, googleStatus, eudamedEOStatus, eudamedDevicesSppsStatus, eudamedCertificatesStatus, switzerlandGoogleStatus, pubmedStatus, swissmedicStatus, SaluteScrapperStatus, SaluteAvvisiScrapperStatus, opendataSwissStatus].includes("loading");
  const isOneSourceFinished = [zefixStatus, FDAScrapperStatus, googleStatus, eudamedEOStatus, eudamedDevicesSppsStatus, eudamedCertificatesStatus, switzerlandGoogleStatus, pubmedStatus, swissmedicStatus, SaluteScrapperStatus, SaluteAvvisiScrapperStatus, opendataSwissStatus].includes("success");
  const sourcesStatus = {
    zefix: zefixStatus,
    fda_scrapper: FDAScrapperStatus,
    google: googleStatus,
    eudamed_eo_scrapper: eudamedEOStatus,
    eudamed_devices_spps_scrapper: eudamedDevicesSppsStatus,
    eudamed_certificates_scrapper: eudamedCertificatesStatus,
    switzerland_google: switzerlandGoogleStatus,
    pubmed_scrapper: pubmedStatus,
    swissmedic_scrapper: swissmedicStatus,
    salute_scrapper: SaluteScrapperStatus,
    salute_avvisi_scrapper: SaluteAvvisiScrapperStatus,
    opendata_swiss: opendataSwissStatus,
  };


  useEffect(() => {
    setSubmitted(false);
  }, [FDAResults, zefixResults, googleResults, eudamedEOResults, eudamedDevicesSppsResults, eudamedCertificatesResults, switzerlandGoogleResults, pubmedResults, swissmedicResults, SaluteResults, SaluteAvvisiResults, opendataSwissResults, formValues, setSubmitted])

  useEffect(() => {
    if (zefixResults?.length >= 0 || !zefixResults) {
      if (page.number === 1) setTotalResults(t => ({ ...t, zefix: zefixResults?.totalResults }))
      setResults(res => ({ ...res, zefix: zefixResults }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zefixResults])

  useEffect(() => {
    if (Object.keys(FDAResults)?.length > 0) {
      if (page.number === 1) setTotalResults(t => ({ ...t, ...FDAResults.total }))
      setResults(res => ({ ...res, ...FDAResults }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [FDAResults])

  useEffect(() => {
    if (Object.keys(googleResults)?.length > 0) {
      if (page.number === 1) setTotalResults(t => ({ ...t, ...googleResults?.total }))
      setResults(res => ({ ...res, ...googleResults?.data }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [googleResults])

  useEffect(() => {
    if (Array.isArray(eudamedEOResults)) {
      if (page.number === 1) setTotalResults(t => ({ ...t, ...eudamedEOResults?.total }))
      setResults(res => ({ ...res, eudamed_economic_operators: eudamedEOResults }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eudamedEOResults])

  useEffect(() => {
    if (Array.isArray(eudamedDevicesSppsResults)) {
      if (page.number === 1) setTotalResults(t => ({ ...t, ...eudamedDevicesSppsResults?.total }))
      setResults(res => ({ ...res, eudamed_devices_spps: eudamedDevicesSppsResults }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eudamedDevicesSppsResults])

  useEffect(() => {
    if (Array.isArray(eudamedCertificatesResults)) {
      if (page.number === 1) setTotalResults(t => ({ ...t, ...eudamedCertificatesResults?.total }))
      setResults(res => ({ ...res, eudamed_certificates: eudamedCertificatesResults }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eudamedCertificatesResults])

  useEffect(() => {
    if (Array.isArray(swissmedicResults)) {
      if (page.number === 1) setTotalResults(t => ({ ...t, ...swissmedicResults?.total }))
      setResults(res => ({ ...res, swissmedic_fscas: swissmedicResults }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swissmedicResults])

  useEffect(() => {
    if (Object.keys(switzerlandGoogleResults)?.length > 0) {
      if (page.number === 1) setTotalResults(t => ({ ...t, ...switzerlandGoogleResults?.total }))
      setResults(res => ({ ...res, switzerland_region_search: switzerlandGoogleResults }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [switzerlandGoogleResults])

  useEffect(() => {
    if (Object.keys(pubmedResults)?.length > 0) {
      if (page.number === 1) setTotalResults(t => ({ ...t, ...pubmedResults?.total }))
      setResults(res => ({ ...res, pubmed: pubmedResults }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pubmedResults])

  useEffect(()=>{
    if(Object.keys(SaluteResults)?.length>0){
      if(page.number===1)  setTotalResults(t=>({...t, ...SaluteResults.total}))
      setResults(res=>({...res,...SaluteResults}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[SaluteResults])

  useEffect(()=>{
    if(Object.keys(SaluteAvvisiResults)?.length>0){
      if(page.number===1)  setTotalResults(t=>({...t, ...SaluteAvvisiResults.total}))
      setResults(res=>({...res,...SaluteAvvisiResults}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[SaluteAvvisiResults])

  useEffect(() => {
    if (Array.isArray(opendataSwissResults)) {
      if (page.number === 1) setTotalResults(t => ({ ...t, ...opendataSwissResults?.total }))
      setResults(res => ({ ...res, opendata_swiss: opendataSwissResults }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opendataSwissResults])


  return { results, totalResults, setTotalResults, areResultsLoading, isOneSourceFinished, submitted, setSubmited: setSubmitted, fetchSwitzerlandResults, sourcesStatus }

}
