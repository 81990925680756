import { useReducer, useState } from "react";
import dayjs from "dayjs";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { makeStyles } from "@mui/styles";
import UpArrowIcon from './icons/upArrow.svg';
import DownArrowIcon from './icons/downArrow.svg';

const useStyles = makeStyles({
    container: {
        position: 'relative',
    },
    selector: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontWeight: 'bold',
        fontSize: '14px',
        color: '#30354F',
        width: '100%',
        border: '1px solid #A6ABC9',
        boxSizing: 'border-box',
        borderRadius: '6px',
        backgroundColor: '#FFFFFF',
        padding: '11px 16px',
        cursor: 'pointer',
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    dropdown: {
        position: 'absolute',
        left: 0,
        top: 'calc(100% + 8px)',
        background: '#FFFFFF',
        border: '1px solid #ECEDF3',
        boxSizing: 'border-box',
        boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: '6px',
        width: 'fit-content',
        zIndex: 3,
    },
    dropdownHeader: {
        height: '40px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '1px solid #E3E3E3',
        paddingInline: '16px',
        fontSize: '14px',
        lineHeight: '18px',
        color: '#5D6598',
        '& > span:last-child': {
            fontSize: '16px',
            cursor: 'pointer',
        }
    },
    dropdownBody: {
        fontSize: '14px',
        padding: '16px 16px 32px 16px',
        color: '#30354F',
    },
    dropdownOption: {
        display: 'flex',
        alignItems: 'center',
        gap: '12px',
        marginBottom: '8px',
        cursor: 'pointer',
        '& input, label': {
            margin: 0,
            padding: 0,
            cursor: 'pointer',
        },
    },
    dateRanges: {
        display: 'flex',
        gap: '24px',
        marginTop: 24,
    },
    dateInput: {
        '& > span': {
            marginBottom: '8px'
        },
        '& > input': {
            height: '40px',
            backgroundColor: '#FFFFFF',
            border: '1px solid #A6ABC9',
            boxSizing: 'border-box',
            borderRadius: '6px',
            paddingLeft: '8px',
            color: '#30354F',
        },
    }
})

const dateRanges = [
    { name: "Last week", unit: "week", number: 1 },
    { name: "Last month", unit: "month", number: 1 },
    { name: "Last 3 months", unit: "month", number: 3 },
    { name: "Last 6 months", unit: "month", number: 6 },
    { name: "Last year", unit: "year", number: 1 },
];

const DateSelector = ({ setDate }) => {
    const classes = useStyles()
    const [key, renderForm] = useReducer((x) => x + 1, 0);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    const [selectedDate, setSelectedDate] = useState('Select a date');
    const onSelectDateClick = () => setIsDropdownOpen(o => !o)

    const handleRangeDate = (e) => {
        const { target: { value } } = e;
        const dateRange = dateRanges.find(d => d.name === value);
        const from = dayjs().subtract(dateRange.number, dateRange.unit).toString();
        const to = dayjs().startOf('date').toString();
        setDate('from')(from);
        setDate('to')(to);
        setSelectedDate(value);
    }

    const handleSelectedDate = (e) => {
        const { target: { value, name } } = e;
        setDate(name)(value);
        const seletedDateText = name + ' ' + value
        setSelectedDate(s => {
            if (s.includes('from') && name !== 'from') {
                const fromText = s.split(' to')?.[0]
                return fromText + ' ' + seletedDateText;
            } else if (s.includes('to') && name !== 'to') {
                const splitedDate = s.split(' to');
                const toText = splitedDate?.[1] ? ' to ' + splitedDate?.[1] : splitedDate?.[0];
                return seletedDateText + ' ' + toText;
            } else {
                return seletedDateText;
            }
        })
    }

    const clearForm = () => {
        setDate('from')('');
        setDate('to')('');
        setSelectedDate('Select a date');
        renderForm()
    }

    return (<ClickAwayListener onClickAway={() => setIsDropdownOpen(false)}>
        <div className={classes.container}>
            <span className={classes.selector} onClick={onSelectDateClick}>
                <span>{selectedDate}</span>
                <img src={isDropdownOpen ? UpArrowIcon : DownArrowIcon} alt='selector arrow' />
            </span>
            <div key={key} className={classes.dropdown}
                style={{ display: !isDropdownOpen && "none" }}>
                <div className={classes.dropdownHeader}>
                    <span>Date</span>
                    <span onClick={clearForm}>Clear</span>
                </div>
                <div className={classes.dropdownBody}>
                    {dateRanges.map(({ name }) => <div key={name} className={classes.dropdownOption}>
                        <input id={name} type='radio' name='range' value={name} onClick={handleRangeDate} />
                        <label htmlFor={name}>{name}</label>
                    </div>)}
                    <div className={classes.dateRanges}>
                        <div className={classes.dateInput}>
                            <span>Start date</span>
                            <input name='from' type="date" onChange={handleSelectedDate} />
                        </div>
                        <div className={classes.dateInput}>
                            <span>End date</span>
                            <input name='to' type="date" onChange={handleSelectedDate} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ClickAwayListener>)
}

export default DateSelector;